import {
    PrecisionAdjustmentT,
    adjustNumberPrecision,
    toDecimal,
} from "../numbers/numbers"
import { DurationUnitT, DurationUnitValueObjT } from "./types"

const secondsPerDay = 86400
const secondsPerHour = 3600
const secondsPerMinute = 60
const secondsPerSecond = 1

/**
 * Converts a duration value from one unit to another.
 *
 * @param obj - DurationUnitValueObjT object containing the duration value and unit that will be converted.
 * @param newUnit - The new unit to convert the duration value to.
 * @param precisionAdjustment - The precision adjustment to apply to the converted value - default floor
 * @returns The converted duration value in the new unit. The value is rounded down to the nearest whole number.
 * @throws Error if the time unit or new time unit is invalid.
 */

const convertDurationUnit = (
    obj: DurationUnitValueObjT,
    newUnit: DurationUnitT,
    precisionAdjustment: PrecisionAdjustmentT = "floor"
): DurationUnitValueObjT => {
    const conversionFactors: Record<DurationUnitT, number> = {
        s: secondsPerSecond,
        m: secondsPerMinute,
        h: secondsPerHour,
        d: secondsPerDay,
    }

    if (!conversionFactors[obj.unit] || !conversionFactors[newUnit]) {
        throw new Error("Invalid duration unit type")
    }

    const totalSeconds = obj.value * conversionFactors[obj.unit]
    const rawValue = totalSeconds / conversionFactors[newUnit]
    const adjustedValue = adjustNumberPrecision(rawValue, precisionAdjustment)

    const durationUnitValueObj: DurationUnitValueObjT = {
        value: adjustedValue,
        unit: newUnit,
    }

    return durationUnitValueObj
}

/**
 * Converts a duration in seconds into the largest whole time unit, considering days, hours, minutes, and seconds.
 * If the duration is evenly divisible by a larger unit, it returns @DurationUnitT with the value in that unit.
 * If not evenly divisible, it returns the duration in seconds.
 *
 * @param seconds The duration in seconds to be converted.
 * @param maxUnit The maximum duration unit to convert the duration to.
 * @returns  @DurationUnitValueObjT object representing the converted duration unit and value.
 *
 * @example
 * const result = secondsToLargestWholeDurationUnit(3600);
 * result: { value: 1, unit: "h" }
 *
 */
const secondsToLargestWholeDurationUnit = (
    seconds: number,
    maxUnit: DurationUnitT
): DurationUnitValueObjT => {
    if (seconds % secondsPerDay === 0 && durationUnitGE(maxUnit, "d")) {
        return {
            value: seconds / secondsPerDay,
            unit: "d",
        }
    }
    if (seconds % secondsPerHour === 0 && durationUnitGE(maxUnit, "h")) {
        return {
            value: seconds / secondsPerHour,
            unit: "h",
        }
    }
    if (seconds % secondsPerMinute === 0 && durationUnitGE(maxUnit, "m")) {
        return {
            value: seconds / secondsPerMinute,
            unit: "m",
        }
    }
    return {
        value: seconds,
        unit: "s",
    }
}

/**
 *
 * Convert seconds to the largest single duration unit where the value is greater than 1.
 * Useful for displaying durations in a more human-readable format.
 * 90s = 1.5min
 * 5400s = 1.5h
 * 86400s = 1d
 * 30s = 30s
 *
 * @param seconds - seconds to convert
 * @returns - DurationUnitValueObjT object representing the converted duration unit and value.
 */
const convertSecondsToLargestSingleDurationUnit = (
    seconds: number
): DurationUnitValueObjT => {
    if (seconds >= secondsPerDay) {
        return {
            value: toDecimal(seconds / secondsPerDay, 2),
            unit: "d",
        }
    }
    if (seconds >= secondsPerHour) {
        return {
            value: toDecimal(seconds / secondsPerHour, 2),
            unit: "h",
        }
    }
    if (seconds >= secondsPerMinute) {
        return {
            value: toDecimal(seconds / secondsPerMinute, 2),
            unit: "m",
        }
    }
    return {
        value: toDecimal(seconds, 2),
        unit: "s",
    }
}

/**
 * Converts a duration unit value object into a displayable format.
 *
 * @param obj - DurationUnitValueObjT object containing the duration value and unit.
 * @returns An object containing the displayable unit label and value.
 */
const durationUnitValueToDisplay = (
    obj: DurationUnitValueObjT
): { unitLabel: string; value: number } => {
    const labelObj: Record<DurationUnitT, string> = {
        s: "sec",
        m: "min",
        h: "hr",
        d: "day",
    }

    const label =
        obj.value === 1 ? labelObj[obj.unit] : labelObj[obj.unit].concat("s")

    return {
        unitLabel: label,
        value: obj.value,
    }
}

/**
 * Compares two duration units and returns true if the first unit is greater than or equal to the second unit.
 *
 * @param unit1 - The first duration unit to compare.
 * @param unit2 - The second duration unit to compare.
 * @returns True if the first unit is greater than or equal to the second unit.
 */
const durationUnitGE = (
    unit1: DurationUnitT,
    unit2: DurationUnitT
): boolean => {
    const unitOrderAsc: Record<DurationUnitT, number> = {
        s: 0,
        m: 1,
        h: 2,
        d: 3,
    }
    return unitOrderAsc[unit1] >= unitOrderAsc[unit2]
}

/**
 * Returns a `Date` object representing the current date and time plus the duration value and unit.
 *
 * @param obj - DurationUnitValueObjT object containing the duration value and unit.
 * @returns A `Date` object representing the calculated date and time.
 */
const getDateNowPlusDuration = (obj: DurationUnitValueObjT): Date => {
    const durationMilliseconds =
        convertDurationUnit(obj, "s", "roundInteger").value * 1000

    const now = new Date()
    const newDate = new Date(now.getTime() + durationMilliseconds)
    return newDate
}

const formatTimestampsToDuration = (start: number, end: number | undefined) => {
    if (!end) return undefined

    // Convert from milliseconds to seconds by dividing by 1000
    const durationMs = end - start
    const durationSeconds = Math.floor(durationMs / 1000)

    // Calculate hours, minutes, seconds
    const hours = Math.floor(durationSeconds / 3600)
    const minutes = Math.floor((durationSeconds % 3600) / 60)
    const seconds = durationSeconds % 60

    return `${hours}h ${minutes}m ${seconds}s`
}

export {
    convertDurationUnit,
    secondsToLargestWholeDurationUnit,
    durationUnitValueToDisplay,
    getDateNowPlusDuration,
    convertSecondsToLargestSingleDurationUnit,
    formatTimestampsToDuration,
}
