import { SubjectRole } from "../../generated/graphql"

type ViewComponentType =
    | "InviteUser"
    | "AdminDashboard"
    | "AdminAddOrganization"
    | "AdminUpdateOrganization"
    | "UserSettings"
    | "AddWells"
    | "WellControlsEdit"
    | "WellControlsLink"
    | "WellControlsUnlink"
    | "WellIndex"
    | "WellDetails"
    | "WellAttributes"
    | "UpdateWellAttributes"
    | "UpdateWellWiringMode"
    | "Notifications"
    | "NotificationsAdmin"
    | "SubscriptionsByRule"
    | "SubscriptionsByUser"
    | "UpdateRule"
    | "AddRule"
    | "WellNotes" // Currently all roles have access to well note crud modals so we only need this one component. Update as needed
    | undefined

type ComponentMappingArrayType = {
    path: string
    component: ViewComponentType
}[]

class ViewComponentPermissions {
    private _role: SubjectRole
    private _urlPathName: string | undefined

    constructor(role: SubjectRole, urlPathName?: string) {
        this._role = role
        this._urlPathName = urlPathName
    }

    private urlToViewComponent(
        urlPathName: string | undefined
    ): ViewComponentType | undefined {
        if (!urlPathName) {
            return undefined
        }

        // Convert URL to lowercase for consistent matching
        const normalizedPath = urlPathName.toLowerCase()

        /** Ordering is important */
        if (normalizedPath.endsWith("/inviteuser")) {
            return "InviteUser"
        } else if (
            normalizedPath.match(/^\/dashboard\/controls\/[\w+/=]+\/edit$/)
        ) {
            return "WellControlsEdit"
        } else if (
            normalizedPath.match(
                /^\/dashboard\/controls\/[\w+/=]+\/(link|unlink)$/
            )
        ) {
            return normalizedPath.endsWith("/link")
                ? "WellControlsLink"
                : "WellControlsUnlink"
        }
        // For individual well page modals
        else if (normalizedPath.endsWith("/controls/edit")) {
            return "WellControlsEdit"
        } else if (normalizedPath.endsWith("/controls/link")) {
            return "WellControlsLink"
        } else if (normalizedPath.endsWith("/controls/unlink")) {
            return "WellControlsUnlink"
        } else if (normalizedPath.match(/^\/wellattributes\/[\w+/=]+\/edit$/)) {
            return "UpdateWellAttributes"
        } else if (
            normalizedPath.match(
                /^\/wellattributes\/[\w+/=]+\/editinstallation$/
            )
        ) {
            return "UpdateWellWiringMode"
        }
        // For specific admin pages
        else if (normalizedPath.endsWith("/addorganization")) {
            return "AdminAddOrganization"
        } else if (
            normalizedPath.match(/^\/admin\/organizations\/[\w+/=]+\/update$/)
        ) {
            return "AdminUpdateOrganization"
        }
        // Specific notifications pages
        else if (normalizedPath.includes("/subscriptionsbyrule")) {
            return "SubscriptionsByRule"
        } else if (normalizedPath.includes("/subscriptionsbyuser")) {
            return "SubscriptionsByUser"
        } else if (normalizedPath.endsWith("updaterule")) {
            return "UpdateRule"
        } else if (normalizedPath.endsWith("addrule")) {
            return "AddRule"
        } else if (
            normalizedPath.includes("admincopy") ||
            normalizedPath.includes("adminmove")
        ) {
            return "NotificationsAdmin"
        }

        const componentMappings: ComponentMappingArrayType = [
            { path: "/admin", component: "AdminDashboard" },
            { path: "/usersettings", component: "UserSettings" },
            { path: "/dashboard/addwells", component: "AddWells" },
            { path: "/dashboard", component: "WellIndex" },
            { path: "/welldetails", component: "WellDetails" },
            { path: "/wellattributes", component: "WellAttributes" },
            { path: "/notifications", component: "Notifications" },
            { path: "/n/m", component: "Notifications" },
            { path: "/wellnotes", component: "WellNotes" },
        ]

        // Efficient matching for simple routes
        for (const mapping of componentMappings) {
            if (normalizedPath.startsWith(mapping.path)) {
                return mapping.component
            }
        }

        return undefined
    }

    private canReadComponentByRole(component: ViewComponentType): boolean {
        if (component === undefined) {
            return false
        }

        switch (this._role) {
            case SubjectRole.PpoAdmin:
                return true
            case SubjectRole.OrgAdmin: {
                const availableComponents: ViewComponentType[] = [
                    "AdminDashboard",
                    "InviteUser",
                    "UserSettings",
                    "AddWells",
                    "WellControlsEdit",
                    "WellIndex",
                    "WellControlsLink",
                    "WellControlsUnlink",
                    "WellDetails",
                    "WellAttributes",
                    "UpdateWellAttributes",
                    "UpdateWellWiringMode",
                    "Notifications",
                    "SubscriptionsByRule",
                    "SubscriptionsByUser",
                    "UpdateRule",
                    "AddRule",
                    "NotificationsAdmin",
                    "WellNotes",
                ]

                return availableComponents.includes(component)
            }
            case SubjectRole.OrgMember: {
                const availableComponents: ViewComponentType[] = [
                    "UserSettings",
                    "WellControlsEdit",
                    "WellIndex",
                    "WellDetails",
                    "WellAttributes",
                    "Notifications",
                    "WellNotes",
                ]

                return availableComponents.includes(component)
            }
            default:
                return false
        }
    }

    public canReadComponent(): boolean {
        const viewComponent = this.urlToViewComponent(this._urlPathName)

        if (viewComponent === undefined || this._role === SubjectRole.Unknown) {
            return false
        }

        return this.canReadComponentByRole(viewComponent)
    }
}

export { ViewComponentPermissions }
