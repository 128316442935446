import { graphql } from "../../generated"

export const MOTION_TIMELINE_QUERY = graphql(`
    query motionTimelineQuery(
        $motionTimelineInput: TimelineInput!
        $intentTimelineInput: TimelineInput!
    ) {
        motionTimeline: eventTimeline(input: $motionTimelineInput) {
            series {
                timeline {
                    ...ControlObservationTimelineFields
                }
            }
        }

        intentTimeline: eventTimeline(input: $intentTimelineInput) {
            series {
                timeline {
                    ...ControlIntentTimelineFields
                }
            }
        }
    }
`)
