import { graphql } from "../../generated"

export const INDIVIDUAL_WELL_PAGE_QUERY = graphql(`
    query individualWellPageQuery(
        $wellInput: GetWellInput
        $equipStats_from: Long
        $equipStats_to: Long
        $historyCount: Int!
        $zoneInfo: ZoneInfo
        $wellControlInput: WellControlSettingsByWellID!
    ) {
        well(input: $wellInput) {
            ...WellIdentificationFields
            alert {
                ...WellAlertFields
            }
            state {
                ...WellControlStateFields
            }
            controlSetting {
                ...ControlSettingFields
            }
            installation {
                ...InstallationFields
            }
            # the controlSettings query in queries/controlSettingHistory - queries configRevision.
            # The well controls modal uses it to check if smart mode should be disabled.
            # By querying it here, it will be cached with apollo and we avoid having to hit the server when opening the well control modal from the individual well page.
            configRevision {
                ...WellConfigurationRevisionParameters
            }
            equipmentMetrics {
                latestSpm {
                    ...DataPointFields
                }
                dailyRunTime {
                    ...DataFrameFields
                }
                windowedRunTime(
                    zoneInfo: $zoneInfo
                    interval: {
                        from: { unixMilliseconds: $equipStats_from }
                        to: { unixMilliseconds: $equipStats_to }
                    }
                ) {
                    ...DataFrameFields
                }
            }
        }
        controlHistory: wellControlSettings(
            last: $historyCount
            input: $wellControlInput
        ) {
            nodes {
                ...ControlSettingFields
            }
        }
    }
`)
