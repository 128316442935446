import { graphql } from "../../generated"

export const CONTROL_INTENT_TIMELINE_FRAGMENT = graphql(`
    fragment ControlIntentTimelineFields on WellControlIntentTimelineEvent {
        start {
            unixMilliseconds
        }
        stop {
            unixMilliseconds
        }
        controlIntenState: state
        __typename
    }
`)

export const CONTROL_OBSERVATION_TIMELINE_FRAGMENT = graphql(`
    fragment ControlObservationTimelineFields on WellControlObservationTimelineEvent {
        start {
            unixMilliseconds
        }
        stop {
            unixMilliseconds
        }
        controlObservationState: state
        __typename
    }
`)
