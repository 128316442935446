import { FunctionComponent } from "react"
import styles from "./component.module.scss"
import Input from "../../../shared/input"
import { WellNotesFormT } from "./form"
import { ApolloError } from "@apollo/client"
import classNames from "classnames"
import Alert from "../../../shared/alert"

interface WellNotesComponentI {
    // input change handlers
    handleNoteChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    handleOccurredAtChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    // blur handlers
    handleOccurredAtBlur: () => void
    handleNoteBlur: () => void
    //
    formState: WellNotesFormT
    // states
    loading: boolean
    fetchError: ApolloError | undefined
    mutationError: ApolloError | undefined
    formType: "add" | "update"
}

const WellNotesFormComp: FunctionComponent<WellNotesComponentI> = ({
    handleNoteChange,
    handleOccurredAtChange,
    handleOccurredAtBlur,
    handleNoteBlur,
    formState,
    formType,
    fetchError,
    mutationError,
    loading,
}) => {
    const errExists = Boolean(fetchError) || Boolean(mutationError)
    const errMsg = fetchError
        ? "There was a problem getting the well note."
        : mutationError
        ? "There was a problem updating the well note."
        : null
    const labelWord = formType === "update" ? "Update" : "Add"

    return (
        <>
            <div
                className={classNames(
                    styles.formContainer,
                    loading && styles.loading
                )}
            >
                <form>
                    <Input
                        type="textArea"
                        onChange={handleNoteChange}
                        onBlur={handleNoteBlur}
                        value={formState.data.note}
                        orientation="vertical"
                        label={`${labelWord} well note`}
                        inputCustomClass={styles.textArea}
                        message={
                            formState.validation.note
                                ? formState.validation.note
                                : ""
                        }
                        specializedClass={
                            formState.validation.note ? "danger" : "default"
                        }
                        disabled={loading || errExists}
                    />
                    <Input
                        type="datetime-local"
                        onChange={handleOccurredAtChange}
                        onBlur={handleOccurredAtBlur}
                        value={formState.data.occurredAt}
                        orientation="vertical"
                        label="Occurred at"
                        disabled={loading || errExists}
                        message={
                            formState.validation.occurredAt
                                ? formState.validation.occurredAt
                                : ""
                        }
                        specializedClass={
                            formState.validation.occurredAt
                                ? "danger"
                                : "default"
                        }
                    />
                </form>
            </div>
            {errExists && (
                <Alert message={errMsg} alertType="danger" isCloseable={true} />
            )}
        </>
    )
}

export default WellNotesFormComp
