import { FunctionComponent } from "react"
import ModalHeader from "../../../shared/modalHeader"
import { TrexNavigator } from "../../../../classes/navigator/navigator"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalFooter from "../../../shared/modalFooter"
import WellNotesFormComp from "../wellNotesForm/component"
import useWellNotesForm, { WellNotesFormManager } from "../wellNotesForm/form"
import { useMutation } from "@apollo/client"
import { WELL_NOTES_ADD } from "../../../../graphql/mutations/well_notes"
import { WELL_NOTES_FOR_IWP } from "../../../../graphql/queries/wellNotes"
import styles from "./add.module.scss"
import { WELL_NOTES_ABS_ROUTE } from "../../../.."

const WellNotesAddModal: FunctionComponent = () => {
    // hooks
    const navigate = useNavigate()
    const { key: locationKey, pathname } = useLocation()
    // resolve wellId from the url
    const { wellId } = useParams()
    if (!wellId) {
        throw new Error("wellId not resolved from url.")
    }

    // assign mutation for add well note
    const [addWellNote, { loading: mutationLoading, error: mutationError }] =
        useMutation(WELL_NOTES_ADD)

    //  use the custom hook 'useWellNotesForm' to manage the form state and validation
    const {
        formState,
        setFormState,
        handleNoteChange,
        handleOccurredAtChange,
        handleNoteBlur,
        handleOccurredAtBlur,
    } = useWellNotesForm({
        data: {
            note: "",
            occurredAt: "",
            wellIDs: [wellId],
        },
        submitDisabled: true,
        validation: {
            note: "",
            occurredAt: "",
        },
    })

    // form submission handler
    const handleSubmitAddNote = async () => {
        const formManager = new WellNotesFormManager(formState.data)
        // if the form is not valid - set updated state and do not submit by returning.
        // our class and form state prevent submit being called in a not valid state - this is a safety net
        if (!formManager.isFormValid) {
            setFormState({
                data: formManager.formData,
                submitDisabled: true,
                validation: {
                    note: formManager.noteValidationState,
                    occurredAt: formManager.occurredAtValidationState,
                },
            })
            return
        }

        // submit mutation
        const addVariables = formManager.getWellNotesCreateInput()
        await addWellNote({
            variables: { WellNotesCreateInput: addVariables },
            refetchQueries: [WELL_NOTES_FOR_IWP],
        })
            .then(() => {
                navigate(WELL_NOTES_ABS_ROUTE(wellId))
            })
            .catch((e) => {
                // mutation error will be passed to the form from the mutation variables assigned above. This error is passed to the form for error handling
                console.error("Error adding well note:", e)
            })
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                title="Add Well Note"
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: pathname,
                        },
                        navigate
                    )
                }
            />
            <WellNotesFormComp
                handleNoteChange={handleNoteChange}
                handleOccurredAtChange={handleOccurredAtChange}
                handleNoteBlur={handleNoteBlur}
                handleOccurredAtBlur={handleOccurredAtBlur}
                formState={formState}
                loading={mutationLoading}
                mutationError={mutationError}
                fetchError={undefined} // no fetching necessary for add note
                formType="add"
            />
            <ModalFooter
                advanceText="Add Note"
                disableAdvance={
                    formState.submitDisabled ||
                    mutationLoading ||
                    Boolean(mutationError)
                }
                handleAdvanceClick={handleSubmitAddNote}
            />
        </div>
    )
}

export default WellNotesAddModal
