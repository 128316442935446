import { FunctionComponent, ReactNode } from "react"
import "./wellIndexPage.module.scss"
import { useQuery } from "@apollo/client"
import { GET_INDEX_WELLS } from "../../graphql/queries/wells"
import { WellIndexTableDataI } from "../../components/wellIndexTable/tableTypes"
import { formatWellsData } from "../../components/wellIndexTable/tableUtils"
import WellIndexTable from "../../components/wellIndexTable"
import styles from "./wellIndexPage.module.scss"
import { SyncLoader } from "react-spinners"
import EmptyState from "../../components/wellIndexTable/emptyState"
import { useViewerContext } from "../../context/ViewerContext"
import dayjs from "dayjs"
import { getZoneInfoFromEnum } from "../../graphql/enum/zoneInfo"

const WellIndexPage: FunctionComponent = () => {
    const zoneInfo = useViewerContext().getViewer().identity.zoneInfo
    const { error, loading, data } = useQuery(GET_INDEX_WELLS, {
        variables: {
            first: 1000,
            last: null,
            before: null,
            after: null,
            equipStats_from: dayjs()
                .tz(getZoneInfoFromEnum(zoneInfo))
                .subtract(8, "day")
                .startOf("day")
                .valueOf(),
            equipStats_to: dayjs()
                .tz(getZoneInfoFromEnum(zoneInfo))
                .startOf("day")
                .valueOf(),
            zoneInfo: zoneInfo,
        },
        fetchPolicy: "network-only",
    })

    let body: ReactNode

    if (error) {
        console.error("Error - Well index page:", error)
        body = <Error />
    }
    if (loading) {
        body = <Loading />
    }
    if (data) {
        const formattedData: WellIndexTableDataI[] = formatWellsData(data)
        if (formattedData.length === 0) {
            body = <EmptyState />
        } else {
            body = <WellIndexTable data={formattedData} />
        }
    }

    return <div className={styles.container}>{body}</div>
}

export default WellIndexPage

const Error: FunctionComponent = () => {
    return (
        <div className={styles.wellIndexError}>
            An error has occurred while loading your wells.
        </div>
    )
}

const Loading: FunctionComponent = () => {
    return (
        <div className={styles.wellIndexLoader}>
            <SyncLoader color="#1e88e5" size={13} />
        </div>
    )
}
