import { FunctionComponent } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { DataFrameFieldsFragment } from "../../../../generated/graphql"
import { convertSecondsToHoursAndMinutes } from "../../../../util/datesAndTimes/datesAndTimes"
import { cloneDeep } from "lodash"
import { updateIncomingRunTimeForWellIndexBarChart } from "./wellIndexBarChartUtil"
import { WellIndexTableCustomRowClassType } from "../../tableTypes"
import { getZoneInfoFromEnum } from "../../../../graphql/enum/zoneInfo"
import { useViewerContext } from "../../../../context/ViewerContext"

interface WellIndexBarChartPropsI {
    dataFrame: DataFrameFieldsFragment
    cssComplianceClass: WellIndexTableCustomRowClassType
}

const WellIndexBarChart: FunctionComponent<WellIndexBarChartPropsI> = ({
    dataFrame,
    cssComplianceClass,
}) => {
    const zoneInfo = useViewerContext().getViewer().identity.zoneInfo
    /**
     *
     *  High charts mutates data for performance reasons. To prevent mutations to orginal data array need to pass a copy
     *  https://github.com/highcharts/highcharts-react - see : why highcharts mutates my data
     *  https://github.com/highcharts/highcharts/issues/4259
     *
     * */
    const seriesData = updateIncomingRunTimeForWellIndexBarChart(
        cloneDeep(dataFrame.highchartSeries)
    )

    const options = {
        chart: {
            type: "column",
            width: 180,
            height: 50,
            backgroundColor:
                cssComplianceClass === "DivergentClass" ? "#ffebee" : "#fff",
        },
        title: {
            text: "",
        },
        xAxis: {
            type: "datetime",
            visible: false,
        },
        yAxis: {
            visible: false,
            min: 0,
            max: 86400,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        accessibility: {
            enabled: false,
        },
        time: {
            timezone: getZoneInfoFromEnum(zoneInfo),
        },
        tooltip: {
            pointFormatter: function (this: Highcharts.Point) {
                if (this.y === undefined) return
                if (this.y < 0) return null
                if (this.y === 0) return 0 + "h " + 0 + "m"
                else {
                    const { hours, minutes } = convertSecondsToHoursAndMinutes(
                        this.y
                    )
                    return hours + "h " + minutes + "m"
                }
            },
            backgroundColor: "#37474f",
            style: {
                color: "#fff", // Text color
            },
            xDateFormat: "%A, %b %e",
            hideDelay: 100,
        },
        plotOptions: {
            column: {
                colorByPoint: true,
                colors: ["#FFB74D", "#1e88e5"],
                minPointLength: 2,
            },
        },
        series: [
            {
                data: seriesData,
            },
        ],
    }

    return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default WellIndexBarChart
