import { FunctionComponent, ReactNode } from "react"
import { useQuery } from "@apollo/client"
import styles from "./wellNotes.module.scss"
import dayjs from "dayjs"
import { SyncLoader } from "react-spinners"
import { WELL_NOTES_FOR_IWP } from "../../../graphql/queries/wellNotes"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import Button from "../../shared/button"
import { getFragmentData } from "../../../generated"
import { WELL_NOTES_FOR_IWP_FRAG } from "../../../graphql/fragments/wellnote"
import { EmptyState, ErrorMessage } from "../../shared/graphQlResponse"
import { WellNotesForIwpFieldsFragment } from "../../../generated/graphql"
import {
    WELL_NOTES_ABS_ROUTE,
    WELL_NOTES_ADD_CHILD_ROUTE,
    WELL_NOTES_UPDATE_CHILD_ROUTE,
} from "../../.."

const WellNotes: FunctionComponent = () => {
    const { wellId } = useParams()

    if (!wellId) {
        throw Error("wellId not resolved from url.")
    }
    const { loading, error, data } = useQuery(WELL_NOTES_FOR_IWP, {
        variables: { input: { wellIDs: [wellId] } },
        fetchPolicy: "network-only",
    })

    let body: ReactNode
    if (loading) {
        body = <Loading />
    }

    if (error) {
        body = <Error />
    }

    if (data) {
        const wn = data?.wellNotesByWellIDs?.wellNotes || []
        const wellNotes = [...getFragmentData(WELL_NOTES_FOR_IWP_FRAG, wn)]
        if (wellNotes.length === 0) {
            body = <WellNoteEmptyState wellId={wellId} />
        } else {
            body = <WellNotesList wellNotes={wellNotes} />
        }
    }

    return (
        <div className={styles.container}>
            <Outlet />
            {body}
        </div>
    )
}

export default WellNotes

interface WellNotesHeader {
    wellId: string
}

const WellNotesHeader: FunctionComponent<WellNotesHeader> = ({ wellId }) => {
    const navigate = useNavigate()
    return (
        <div className={styles.header}>
            <Button
                status="primary"
                condition="default"
                handleButtonClick={() => {
                    navigate(
                        `${WELL_NOTES_ABS_ROUTE(
                            wellId
                        )}/${WELL_NOTES_ADD_CHILD_ROUTE}`
                    )
                }}
            >
                Add well note
            </Button>
        </div>
    )
}

interface WellNotesListI {
    wellNotes: WellNotesForIwpFieldsFragment[]
}

const WellNotesList: FunctionComponent<WellNotesListI> = ({ wellNotes }) => {
    const navigate = useNavigate()
    return (
        <>
            <WellNotesHeader wellId={wellNotes[0].well.id} />
            <div className={styles.notesList}>
                {wellNotes
                    .slice()
                    .sort((a, b) => {
                        return (
                            b.occurredAt.unixSeconds - a.occurredAt.unixSeconds
                        )
                    })
                    .map((wellNote) => {
                        return (
                            <div key={wellNote.id} className={styles.noteCard}>
                                <div className={styles.noteContent}>
                                    {wellNote.note}
                                </div>
                                <div className={styles.noteMeta}>
                                    <div>
                                        Created by:{" "}
                                        {wellNote.createdBy.identity?.fullName}{" "}
                                    </div>
                                    <div>
                                        Updated by:{" "}
                                        {wellNote.updatedBy.identity?.fullName}
                                    </div>
                                    <div>
                                        Occurred at:{" "}
                                        {dayjs
                                            .unix(
                                                wellNote.occurredAt.unixSeconds
                                            )
                                            .format("MMM D, YYYY h:mm A")}
                                    </div>
                                </div>
                                <div className={styles.updateContainer}>
                                    <Button
                                        status="secondary"
                                        customButtonClassName="button-table-row-outlined"
                                        condition="default"
                                        disabled={false}
                                        handleButtonClick={() => {
                                            navigate(
                                                `${WELL_NOTES_ABS_ROUTE(
                                                    wellNote.well.id
                                                )}/${WELL_NOTES_UPDATE_CHILD_ROUTE(
                                                    wellNote.id
                                                )}`
                                            )
                                        }}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </>
    )
}

const Error: FunctionComponent = () => {
    return (
        <>
            <ErrorMessage
                message="An error occured while loading notes for this well."
                outSideDivStyle={styles.error}
            />
        </>
    )
}

const Loading: FunctionComponent = () => {
    return (
        <div className={styles.loader}>
            <SyncLoader color="#1e88e5" size={13} />
        </div>
    )
}

const WellNoteEmptyState: FunctionComponent<{ wellId: string }> = ({
    wellId,
}) => {
    const navigate = useNavigate()
    return (
        <EmptyState outSideDivStyle={styles.emptyStateContainer}>
            <div className={styles.emptyTitle}>
                <div>No notes found for this well.</div>
            </div>
            <Button
                status="primary"
                condition="default"
                handleButtonClick={() => {
                    navigate(
                        `${WELL_NOTES_ABS_ROUTE(
                            wellId
                        )}/${WELL_NOTES_ADD_CHILD_ROUTE}`
                    )
                }}
            >
                Add well note
            </Button>
        </EmptyState>
    )
}
