import { ZipCelXCell, ZipCelXRow, ZipCelXSheet } from "zipcelx"
import HighStock from "highcharts/highstock"
import {
    convertSecondsToHours,
    formatUnixMillToMMDDYYY_UTC,
    getVariableDaysAgo_StartOfDayUnixMs,
} from "../../../util/datesAndTimes/datesAndTimes"
import { DateIntervalType, WellDetailsUrlType } from "./wellDetailsChartType"
import { capMaxSeconds } from "../../../util/DataFrameHighChartSeriesUtil"
import {
    ControlIntentTimelineFieldsFragment,
    ControlObservationTimelineFieldsFragment,
    ZoneInfo,
} from "../../../generated/graphql"
import { getZoneInfoFromEnum } from "../../../graphql/enum/zoneInfo"
import dayjs from "dayjs"

// { x: Date.UTC(2024, 0, 1, 6, 0), x2: Date.UTC(2024, 0, 1, 18, 0), y: 1, label: "Running", color: 'green' },
export type XrangePoint = {
    x: number
    x2: number | undefined
    y: number | undefined
    name: string
    color:
        | string
        | HighStock.GradientColorObject
        | HighStock.PatternObject
        | undefined
}

const updateIncomingRunTimeForWellDetailsLineChart = (
    highChartsSeries: (number | null)[][] | undefined
) => {
    if (!highChartsSeries) return
    // Following function will mutate the original array
    // Extract timestamp and runtime value
    const extractedRuntime = highChartsSeries.map((point) => {
        const timestamp = point[0]
        const seconds = point[1]

        return [timestamp, seconds]
    })
    /** cap max seconds to 86400 (1 day) */
    const updatedHighChartSeries_capMaxSeconds = capMaxSeconds(extractedRuntime)

    return updatedHighChartSeries_capMaxSeconds
}

const updateIncomingIdleTimeForWellDetailsLineChart = (
    highChartsSeries: (number | null)[][] | undefined
) => {
    if (!highChartsSeries) return
    // Following function will mutate the original array
    // Extract timestamp and runtime value
    const extractedRuntime = highChartsSeries.map((point) => {
        const timestamp = point[0]
        const seconds = point[2]

        return [timestamp, seconds]
    })
    /** cap max seconds to 86400 (1 day) */
    const updatedHighChartSeries_capMaxSeconds = capMaxSeconds(extractedRuntime)

    return updatedHighChartSeries_capMaxSeconds
}

const updateIncomingUnknownTimeForWellDetailsLineChart = (
    highChartsSeries: (number | null)[][] | undefined
) => {
    if (!highChartsSeries) return
    // Following function will mutate the original array
    // Extract timestamp and runtime value
    const extractedRuntime = highChartsSeries.map((point) => {
        const timestamp = point[0]
        const seconds = point[3]

        return [timestamp, seconds]
    })
    /** cap max seconds to 86400 (1 day) */
    const updatedHighChartSeries_capMaxSeconds = capMaxSeconds(extractedRuntime)

    return updatedHighChartSeries_capMaxSeconds
}

const formatControlForWellDetailsLineChart = (
    data:
        | ControlIntentTimelineFieldsFragment[]
        | ControlObservationTimelineFieldsFragment[]
        | undefined
): XrangePoint[] => {
    const points: XrangePoint[] = []
    if (!data) return points

    data.forEach((event) => {
        let color = ""
        let name = ""
        if (event.__typename === "WellControlIntentTimelineEvent") {
            switch (event.controlIntenState) {
                case "RUNNING":
                    color = "#02C944"
                    break
                case "STOPPED":
                    color = "#0398FC"
                    break
                case "UNKNOWN":
                    color = "#BF42F5"
                    break
            }
            points.push({
                x: event.start.unixMilliseconds,
                x2: event.stop.unixMilliseconds,
                y: 0,
                name: event.controlIntenState,
                color: color,
            })
        }

        if (event.__typename === "WellControlObservationTimelineEvent") {
            switch (event.controlObservationState) {
                case "RUNNING":
                    color = "#02C944"
                    name = "RUNNING"
                    break
                case "STOPPED":
                    color = "#0398FC"
                    name = "STOPPED"
                    break
                case "UNKNOWN":
                    color = "#BF42F5"
                    name = "NOT AVAILABLE"
                    break
            }
            points.push({
                x: event.start.unixMilliseconds,
                x2: event.stop.unixMilliseconds,
                y: 1,
                name: name,
                color: color,
            })
        }
    })

    return points
}

/**
 *
 *  Function used to convert incoming highChart series to a format usable by zipcelx
 *  https://github.com/egeriis/zipcelx/wiki/How-to-use
 *
 */
const convertToZipcelxFormat = (array: (number | null)[][]): ZipCelXSheet => {
    const zipcelxSheet: ZipCelXSheet = {
        data: [
            [
                { type: "string", value: "Date" },
                { type: "string", value: "Run Time (Hr)" },
            ],
        ],
    }
    for (const [timestamp, seconds] of array) {
        // convert unixSeconds to 'mm-dd-yyyy'format
        const convertedTimeStamp: ZipCelXCell = timestamp
            ? { value: formatUnixMillToMMDDYYY_UTC(timestamp), type: "string" }
            : { value: "", type: "string" }
        // convert seconds to hours as a decimal, with 2 decimal places. Return null as empty string
        let hours: ZipCelXCell
        if (seconds === 0 || seconds) {
            hours = { value: convertSecondsToHours(seconds), type: "number" }
        } else {
            hours = { value: "", type: "string" }
        }

        const row: ZipCelXRow = [convertedTimeStamp, hours]
        zipcelxSheet.data.push(row)
    }

    return zipcelxSheet
}

/**
 *  Function used to convert new runtime chart data to a format usable by zipcelx
 *  Data format: [[timestamp, runtime][], [timestamp, idletime][], [timestamp, unknownTime][]]
 */
const convertNewRuntimeToCelx = (
    arrayOfArrays: (number | null)[][][],
    zoneInfo: ZoneInfo
): ZipCelXSheet => {
    const tz = getZoneInfoFromEnum(zoneInfo)
    const zipcelxSheet: ZipCelXSheet = {
        data: [
            [
                {
                    type: "string",
                    value: `Date (${tz})`,
                },
                { type: "string", value: "Run Time (Hr)" },
                { type: "string", value: "Idle Time (Hr)" },
                { type: "string", value: "Not Available Time (Hr)" },
            ],
        ],
    }

    // All arrays have the same length and aligned timestamps
    const length = arrayOfArrays[0]?.length || 0
    for (let i = 0; i < length; i++) {
        const timestamp = arrayOfArrays[0][i][0]

        // convert unixSeconds to 'mm-dd-yyyy' format
        const convertedTimeStamp: ZipCelXCell = timestamp
            ? {
                  value: dayjs(timestamp).tz(tz).format("MM-DD-YYYY HH:mm:ss"),
                  type: "string",
              }
            : { value: "", type: "string" }

        // Process runtime, idletime, and unknown time
        const runtimeSeconds = arrayOfArrays[0][i][1]
        const idletimeSeconds = arrayOfArrays[1][i][1]
        const unknownTimeSeconds = arrayOfArrays[2][i][1]

        // Convert seconds to hours for each metric
        const runtime: ZipCelXCell =
            runtimeSeconds === 0 || runtimeSeconds
                ? {
                      value: convertSecondsToHours(runtimeSeconds),
                      type: "number",
                  }
                : { value: "", type: "string" }

        const idletime: ZipCelXCell =
            idletimeSeconds === 0 || idletimeSeconds
                ? {
                      value: convertSecondsToHours(idletimeSeconds),
                      type: "number",
                  }
                : { value: "", type: "string" }

        const unknowntime: ZipCelXCell =
            unknownTimeSeconds === 0 || unknownTimeSeconds
                ? {
                      value: convertSecondsToHours(unknownTimeSeconds),
                      type: "number",
                  }
                : { value: "", type: "string" }

        const row: ZipCelXRow = [
            convertedTimeStamp,
            runtime,
            idletime,
            unknowntime,
        ]
        zipcelxSheet.data.push(row)
    }

    return zipcelxSheet
}

// filterXRangePointsToDate will find all points in an array of XrangePoints
// that fall on the same date as the targetDate
function filterXRangePointsToDate(
    arrayOfXrange: XrangePoint[],
    targetDate: Date
) {
    // Create dates for day before, target day, and day after
    const dayBefore = new Date(targetDate)
    dayBefore.setDate(dayBefore.getDate() - 1)
    dayBefore.setHours(0, 0, 0, 0)

    const targetStart = new Date(targetDate)
    targetStart.setHours(0, 0, 0, 0)

    const dayAfter = new Date(targetDate)
    dayAfter.setDate(dayAfter.getDate() + 1)
    dayAfter.setHours(23, 59, 59, 999)

    return arrayOfXrange.filter((point) => {
        const pointStart = new Date(point.x)
        const pointEnd = point.x2 ? new Date(point.x2) : pointStart

        // Check if:
        // 1. Point starts before or during target date AND ends after or during target date
        // 2. Point spans any part of the target date
        const spansTargetDate =
            (pointStart <= targetStart && pointEnd >= targetStart) || // starts before, ends during/after
            (pointStart >= targetStart &&
                pointStart <= dayAfter &&
                pointEnd >= dayBefore) // starts during, ends during/after

        return spansTargetDate
    })
}

function convertOldRuntimeToCSV(arrayOfArrays: (number | null)[][]) {
    const csvArray = arrayOfArrays.map(([timestamp, seconds]) => {
        const utcDate = timestamp ? formatUnixMillToMMDDYYY_UTC(timestamp) : ""
        let hours
        if (seconds === 0 || seconds) {
            hours = convertSecondsToHours(seconds)
        } else {
            hours = ""
        }

        return `${utcDate},${hours}`
    })
    // Add header row
    csvArray.unshift("Date ,Run Time (Hr)")
    return csvArray.join("\n")
}

function convertNewRuntimeToCSV(
    arrayOfArrays: (number | null)[][][],
    zoneInfo: ZoneInfo
) {
    // shape of incoming data: [[timestamp, runtime][], [timestamp, idletime][], [timestamp, unknownTime][]]
    // output should be: Date, Run Time (Hr), Idle Time (Hr), Not Available Time (Hr)
    const tz = getZoneInfoFromEnum(zoneInfo)
    const csvArray = arrayOfArrays[0].map((_, index) => {
        const timestamp = arrayOfArrays[0][index][0]
        const utcDate = timestamp
            ? dayjs(timestamp).tz(tz).format("MM-DD-YYYY HH:mm:ss")
            : ""
        const runTime =
            arrayOfArrays[0].length && arrayOfArrays[0][index][1] !== null
                ? convertSecondsToHours(arrayOfArrays[0][index][1])
                : ""
        const idleTime =
            arrayOfArrays[1].length && arrayOfArrays[1][index][1] !== null
                ? convertSecondsToHours(arrayOfArrays[1][index][1])
                : ""
        const unknownTime =
            arrayOfArrays[2].length && arrayOfArrays[2][index][1] !== null
                ? convertSecondsToHours(arrayOfArrays[2][index][1])
                : ""

        return `${utcDate},${runTime},${idleTime},${unknownTime}`
    })
    // Add header row
    csvArray.unshift(
        `Date (${tz}), Run Time (Hr), Idle Time (Hr), Not Available Time (Hr)`
    )
    return csvArray.join("\n")
}

function convertWellMotionToCSV(
    arrayOfXrange: XrangePoint[],
    zoneInfo: ZoneInfo
) {
    // shape of incoming data: [from: timestamp, to: timestamp, motion: string][]
    // output should be: Start Time, End Time, Motion, Intent or Observation
    const csvArray: string[] = []
    const tz = getZoneInfoFromEnum(zoneInfo)

    arrayOfXrange.forEach((point) => {
        const startTime = point.x
            ? dayjs(point.x).tz(tz).format("MM-DD-YYYY HH:mm:ss")
            : ""
        const endTime = point.x2
            ? dayjs(point.x2).tz(tz).format("MM-DD-YYYY HH:mm:ss")
            : ""
        csvArray.push(`${startTime},${endTime},${point.name}`)
    })

    // Add header row
    csvArray.unshift(`Start Time (${tz}), End Time (${tz}), Motion`)
    return csvArray.join("\n")
}

const createWellDetailsSearchParams = (
    from: number | undefined,
    to: number | undefined,
    dateInterval: DateIntervalType | null
) => {
    const obj: WellDetailsUrlType = {}
    if (from) {
        obj.from = from.toString()
    }
    if (to) {
        obj.to = to.toString()
    }
    if (dateInterval) {
        obj.dateInterval = dateInterval
    }
    return obj
}

const isWellDetailsRangeValid = (
    from: string | undefined,
    to: string | undefined
) => {
    const minTimestamp = 1640995200000
    const maxTimestamp = Date.now()

    if (!to || !from) return false
    if (to <= from) return false

    const toParsed = parseInt(to)
    const fromParsed = parseInt(from)

    if (toParsed <= minTimestamp || toParsed >= maxTimestamp) {
        return false
    }
    if (fromParsed <= minTimestamp || fromParsed >= maxTimestamp) {
        return false
    }

    return { to: toParsed, from: fromParsed }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDefaultMinMax = (highChartsSeries: any[][] | undefined) => {
    const unixMilliSecondsNowUTC = Date.now()
    if (!highChartsSeries) {
        return {
            to: getVariableDaysAgo_StartOfDayUnixMs(31),
            from: unixMilliSecondsNowUTC,
        }
    } else {
        return {
            to: highChartsSeries[0][0] as number,
            from: unixMilliSecondsNowUTC,
        }
    }
}

const getAggregationUnits = (value: DateIntervalType) => {
    let chartAggregationUnits: [string, number[] | null][]
    let selected: DateIntervalType

    // get aggregation units
    switch (value) {
        case "hour": {
            chartAggregationUnits = [["hour", [1]]]
            selected = "hour"
            break
        }
        case `day`: {
            chartAggregationUnits = [["day", [1]]]
            selected = "day"
            break
        }
        case "week": {
            chartAggregationUnits = [["week", [1]]]
            selected = "week"
            break
        }
        case "month": {
            chartAggregationUnits = [["month", [1]]]
            selected = "month"
            break
        }
        default:
            selected = "day"
            chartAggregationUnits = [["day", [1]]]
    }

    return {
        selected,
        chartAggregationUnits,
    }
}

const valuesInsideChartView = (series: Highcharts.Series[]) => {
    const allPoints = series.map((s) => s.points)
    return allPoints ? true : false
}

/**
 *
 * To be used in HighStock.Chart.prototype.getCSV override
 *
 * @param points Incoming series data from highcharts chart reference
 * @returns number[][] of x
 */
const getHighChartsDataInView = (points: HighStock.Point[]) => {
    const returnData = []

    for (let i = 0; i < points.length; i++) {
        const plotX = points[i].plotX
        const x = points[i].x
        let y: number | undefined | null = points[i].y

        if (typeof y !== "number") {
            y = null
        }

        if (typeof plotX === "number" && plotX >= 0) {
            returnData.push([x, y])
        }
    }
    return returnData
}

const getXrangePointsInView = (points: HighStock.Point[]) => {
    const returnData: XrangePoint[] = []

    for (let i = 0; i < points.length; i++) {
        const plotX = points[i].plotX
        const x = points[i].x
        const x2 = points[i].x2
        const y = points[i].y
        const name = points[i].name
        const color = points[i].color

        if (typeof plotX === "number" && plotX >= 0) {
            returnData.push({ x: x, x2, y, name, color })
        }
    }
    return returnData
}

export {
    convertToZipcelxFormat,
    convertNewRuntimeToCelx,
    convertOldRuntimeToCSV,
    convertNewRuntimeToCSV,
    convertWellMotionToCSV,
    createWellDetailsSearchParams,
    filterXRangePointsToDate,
    isWellDetailsRangeValid,
    getDefaultMinMax,
    getAggregationUnits,
    valuesInsideChartView,
    getXrangePointsInView,
    getHighChartsDataInView,
    updateIncomingRunTimeForWellDetailsLineChart,
    updateIncomingIdleTimeForWellDetailsLineChart,
    updateIncomingUnknownTimeForWellDetailsLineChart,
    formatControlForWellDetailsLineChart as updateIncomingControlForWellDetailsLineChart,
}
