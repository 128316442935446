import { DateTimeInput } from "../../generated/graphql"

/**
 * Formats a timestamp in a specific timezone with customizable format options
 * @param timestamp - Unix timestamp in milliseconds
 * @param timezone - IANA timezone string (e.g., 'America/New_York', 'Europe/London')
 * @param formatOptions - Optional Intl.DateTimeFormatOptions to customize the format
 * @returns Formatted date string in the specified timezone
 */
function formatTimestampWithTimezone(
    timestamp: number,
    timezone = "America/Chicago", // Default timezone
    formatOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
    }
): string {
    return new Intl.DateTimeFormat("en-US", {
        ...formatOptions,
        timeZone: timezone,
    }).format(timestamp)
}

/**
 * Converts a Unix timestamp in milliseconds to a local date in the format "mm/dd/yy".
 * @param unixMilliSeconds - A number representing the Unix timestamp in milliseconds to be converted.
 * @returns A string representing the local date in the format "mm/dd/yy".
 */
const convertUnixMillisecondsToLocalDate = (
    unixMilliSeconds: number,
    options: Intl.DateTimeFormatOptions | undefined = {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
    }
) => {
    const utcDate = new Date(unixMilliSeconds)
    const localDate = utcDate.toLocaleDateString(undefined, options)
    return localDate
}

const convertUnixMillisecondsToLocalDate_Chicago = (
    unixMilliSeconds: number,
    options: Intl.DateTimeFormatOptions | undefined = {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
        timeZone: "America/Chicago",
    }
) => {
    const utcDate = new Date(unixMilliSeconds)
    const localDate = utcDate.toLocaleDateString(undefined, options)
    return localDate
}

/**
 * Converts a Unix timestamp in milliseconds to a local time in the format "hh:mm AM/PM".
 * @param unixMilliSeconds - A number representing the Unix timestamp in milliseconds to be converted.
 * @returns A string representing the local time in the format "hh:mm AM/PM".
 */
const convertUnixMillisecondsToLocalTime = (
    unixMilliSeconds: number,
    options: Intl.DateTimeFormatOptions | undefined = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    }
): string => {
    const date = new Date(unixMilliSeconds)
    const formattedTime = date.toLocaleTimeString(undefined, options)
    return formattedTime
}

const convertUnixMillisecondsToLocalTime_Chicago = (
    unixMilliSeconds: number,
    options: Intl.DateTimeFormatOptions | undefined = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Chicago",
    }
): string => {
    const date = new Date(unixMilliSeconds)
    const formattedTime = date.toLocaleTimeString(undefined, options)
    return formattedTime
}

const convertSecondsToHoursAndMinutes = (seconds: number) => {
    if (typeof seconds !== "number" || seconds < 0) {
        throw new Error(
            "convertSecondsToHoursAndMinutes - input must be non-negative number"
        )
    }

    const hours = Math.floor(seconds / 3600)
    const remainingSeconds = seconds % 3600
    const minutes = Math.floor(remainingSeconds / 60)

    return { hours, minutes }
}

/**
 *
 * @param seconds
 * @returns Total hours rounded to two decimal places
 */
const convertSecondsToHours = (seconds: number | null) => {
    if (!seconds) {
        return (0).toFixed(2)
    }

    return (seconds / 3600).toFixed(2)
}

/**
 *
 * @param days Number of days to subract from current date
 * @returns UnixMilliseconds of the start of the day ([days] days ago)
 */
const getVariableDaysAgo_StartOfDayUnixMs = (days: number) => {
    const currentDate = new Date()
    currentDate.setUTCHours(0, 0, 0, 0)
    currentDate.setUTCDate(currentDate.getUTCDate() - days)
    return currentDate.getTime()
}

const formatUnixMillToMMDDYYY_UTC = (unixMilliseconds: number): string => {
    const date = new Date(unixMilliseconds)
    const month = String(date.getUTCMonth() + 1).padStart(2, "0") // Month is zero-based
    const day = String(date.getUTCDate()).padStart(2, "0")
    const year = date.getUTCFullYear()
    return `${month}-${day}-${year}`
}

// local version
const unixMsToLocalDateTimeInput = (
    unixMilliseconds: number
): DateTimeInput => {
    const date = new Date(unixMilliseconds)

    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1, // getMonth() is zero-indexed, so we add 1
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
    }
}

const unixMsToUtcDateTimeInput = (unixMilliseconds: number): DateTimeInput => {
    const date = new Date(unixMilliseconds)

    return {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth() + 1, // getMonth() is zero-indexed, so we add 1
        day: date.getUTCDate(),
        hour: date.getUTCHours(),
        minute: date.getUTCMinutes(),
        second: date.getUTCSeconds(),
    }
}

// local time set by the browser
const dateToLocalDateTimeDisplay = (date: Date, delimiter = ""): string => {
    return `${date.toLocaleDateString()} ${delimiter} ${date.toLocaleTimeString(
        undefined,
        {
            hour: "numeric",
            minute: "2-digit",
        }
    )}`
}

const unixMsToLocalDateTimeDisplay = (unixMs: number, delimiter = "") => {
    const date = new Date(unixMs)
    return dateToLocalDateTimeDisplay(date, delimiter)
}

const unixMsIsGreaterOrEqualToNow = (unixMilliseconds: number): boolean => {
    return unixMilliseconds >= Date.now()
}

const getMostRecentMidnightUnixMs_Chicago = (): number => {
    const chicagoFormatter = new Intl.DateTimeFormat("en-US", {
        timeZone: "America/Chicago",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })

    const parts = chicagoFormatter.formatToParts(new Date())
    const dateObj = Object.fromEntries(
        parts.map(({ type, value }) => [type, value])
    )

    return new Date(
        `${dateObj.year}-${dateObj.month}-${dateObj.day}T00:00:00.000-06:00`
    ).getTime()
}

export {
    formatTimestampWithTimezone,
    convertUnixMillisecondsToLocalDate,
    convertUnixMillisecondsToLocalDate_Chicago,
    convertUnixMillisecondsToLocalTime,
    convertUnixMillisecondsToLocalTime_Chicago,
    getMostRecentMidnightUnixMs_Chicago,
    convertSecondsToHoursAndMinutes,
    getVariableDaysAgo_StartOfDayUnixMs,
    formatUnixMillToMMDDYYY_UTC,
    convertSecondsToHours,
    unixMsToLocalDateTimeInput,
    unixMsToUtcDateTimeInput,
    dateToLocalDateTimeDisplay,
    unixMsIsGreaterOrEqualToNow,
    unixMsToLocalDateTimeDisplay,
}
