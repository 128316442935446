import { ZoneInfo } from "../../generated/graphql"

export const zoneInfoDisplayMap: Record<ZoneInfo, string> = {
    [ZoneInfo.CentralTimeZone]: "Central Time",
    [ZoneInfo.MountainTimeZone]: "Mountain Time",
    [ZoneInfo.EasternTimeZone]: "Eastern Time",
    [ZoneInfo.PacificTimeZone]: "Pacific Time",
    [ZoneInfo.HawaiiTimeZone]: "Hawaii Time",
    [ZoneInfo.AlaskaTimeZone]: "Alaska Time",
    [ZoneInfo.Unknown]: "Unknown",
}

export const getZoneInfoFromEnumString = (str: string | undefined) => {
    switch (str) {
        case ZoneInfo.CentralTimeZone.toString():
            return ZoneInfo.CentralTimeZone
        case ZoneInfo.MountainTimeZone.toString():
            return ZoneInfo.MountainTimeZone
        case ZoneInfo.EasternTimeZone.toString():
            return ZoneInfo.EasternTimeZone
        case ZoneInfo.PacificTimeZone.toString():
            return ZoneInfo.PacificTimeZone
        case ZoneInfo.HawaiiTimeZone.toString():
            return ZoneInfo.HawaiiTimeZone
        case ZoneInfo.AlaskaTimeZone.toString():
            return ZoneInfo.AlaskaTimeZone
        default:
            return ZoneInfo.Unknown
    }
}

export const getZoneInfoFromEnum = (enm: ZoneInfo | undefined) => {
    switch (enm) {
        case ZoneInfo.CentralTimeZone:
            return "America/Chicago"
        case ZoneInfo.MountainTimeZone:
            return "America/Denver"
        case ZoneInfo.EasternTimeZone:
            return "America/New_York"
        case ZoneInfo.PacificTimeZone:
            return "America/Los_Angeles"
        case ZoneInfo.HawaiiTimeZone:
            return "Pacific/Honolulu"
        case ZoneInfo.AlaskaTimeZone:
            return "America/Anchorage"
        case ZoneInfo.Unknown:
        default:
            return "UTC"
    }
}

export const getDisplayStringFromZoneInfo = (enm: ZoneInfo) => {
    switch (Object.keys(zoneInfoDisplayMap).includes(enm)) {
        case true:
            return zoneInfoDisplayMap[enm]
        case false:
        default:
            return "Unknown"
    }
}
