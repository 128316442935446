import { Table } from "@tanstack/react-table"
import { MyNotificationsTableDataI } from "../tableTypes"
import { FunctionComponent, useEffect, useState } from "react"
import { useBreakPointContext } from "../../../../../../context/breakPointContext"
import {
    faChevronDoubleDown,
    faChevronDoubleUp,
} from "@fortawesome/pro-solid-svg-icons"
import styles from "./actionHeader.module.scss"
import {
    METRIC_DISPLAY_NAME_COLUMN_ID,
    WELL_NAME_COLUMN_ID,
} from "../column/column"
import Button from "../../../../../shared/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Input from "../../../../../shared/input"

interface MyNotificationsActionHeaderI {
    table: Table<MyNotificationsTableDataI>
    globalFilter: string
}

const MyNotificationsActionHeader: FunctionComponent<
    MyNotificationsActionHeaderI
> = ({ table, globalFilter }) => {
    const breakPoint = useBreakPointContext()
    const is_LE_mediumBreakPoint = breakPoint === "sm" || breakPoint === "md"

    // state
    const [showActions, setShowActions] = useState(true)

    // font awesome icon
    const fontAwesomeIcon = showActions
        ? faChevronDoubleUp
        : faChevronDoubleDown

    // styling classes
    // in mobile
    const outerClass =
        is_LE_mediumBreakPoint && !showActions
            ? styles.outerClosed
            : styles.outer

    const metricColumn = table.getColumn(METRIC_DISPLAY_NAME_COLUMN_ID)
    const wellColumn = table.getColumn(WELL_NAME_COLUMN_ID)

    // unique metrics
    const m = metricColumn?.getFacetedUniqueValues().keys() ?? []
    const uniqueMetrics = Array.from(m).sort()

    const w = wellColumn?.getFacetedUniqueValues().keys() ?? []
    const uniqueWells = Array.from(w).sort()

    // filter values
    const metricFilterValue = (metricColumn?.getFilterValue() as string) || ""
    const wellFilterValue = (wellColumn?.getFilterValue() as string) || ""

    // useEffect: if we resize from mobile => desktop sizing we need to setShowActions to true
    useEffect(() => {
        if (!is_LE_mediumBreakPoint) {
            setShowActions(true)
        }
    }, [is_LE_mediumBreakPoint])

    return (
        <div className={outerClass}>
            {is_LE_mediumBreakPoint && (
                <Button
                    handleButtonClick={() => setShowActions(!showActions)}
                    status="primary"
                    customButtonClassName={styles.mobileButton}
                >
                    <FontAwesomeIcon icon={fontAwesomeIcon} color="#fff" />
                </Button>
            )}
            {showActions && (
                <div className={styles.container}>
                    <div className={styles.containerLeft}>
                        <div className={styles.filter}>
                            <Input
                                onChange={(e) => {
                                    metricColumn?.setFilterValue(e.target.value)
                                }}
                                orientation="horizontal"
                                type="datalist"
                                placeHolder="Filter By Metric"
                                datalistId="alertRuleMetric"
                                options={uniqueMetrics}
                                specializedClass="noMessage"
                                value={metricFilterValue}
                            />
                        </div>
                        <div className={styles.filter}>
                            <Input
                                onChange={(e) => {
                                    wellColumn?.setFilterValue(e.target.value)
                                }}
                                orientation="horizontal"
                                type="datalist"
                                placeHolder="Filter By Well"
                                datalistId="wellDatalist"
                                options={uniqueWells}
                                specializedClass="noMessage"
                                value={wellFilterValue}
                            />
                        </div>
                    </div>
                    <div className={styles.containerRight}>
                        <div className={styles.globalFilter}>
                            <Input
                                value={globalFilter}
                                onChange={(e) => {
                                    table.setGlobalFilter(e.target.value)
                                }}
                                type="text"
                                orientation="horizontal"
                                placeHolder="Search Columns"
                                specializedClass="noMessage"
                                inputCustomClass={styles.searchFilter}
                            />
                        </div>
                        <Button
                            handleButtonClick={() => {
                                table.setGlobalFilter("")
                                metricColumn?.setFilterValue("")
                                wellColumn?.setFilterValue("")
                            }}
                            status="secondary"
                            condition="default"
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MyNotificationsActionHeader
