// Use this class to manage the value of the DateTimePicker component
export class DateTimePickerValueManager {
    public value: string
    constructor(value: string) {
        this.value = value
    }

    public getUnixMilliseconds(): number {
        const unixMs = new Date(this.value).getTime()
        if (typeof unixMs == "number" && unixMs > 0) {
            return unixMs
        }
        throw Error(
            "DateTimePickerValueManager.getUnixMilliseconds() - did not return a number greater than 0"
        )
    }
}
