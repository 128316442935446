import { useState } from "react"
import { objectHasNonUndefinedFields } from "../../../../util/objects/objects"
import {
    WellNotesCreateInput,
    WellNotesUpdateInput,
} from "../../../../generated/graphql"
import { DateTimePickerValueManager } from "../../../shared/input/dateAndTimePickerManager"

// well notes form types
export type WellNotesFormT = {
    data: WellNotesFormData
    validation: WellNotesFormValidation
    submitDisabled: boolean
}

type WellNotesFormData = {
    note: string
    occurredAt: string
    wellIDs?: string[]
    noteID?: string
}

type WellNotesFormValidation = {
    note: string | undefined
    occurredAt: string | undefined
}

// Custom hook to manage the state of well notes form.
const useWellNotesForm = (initialData: WellNotesFormT) => {
    const [formState, setFormState] = useState<WellNotesFormT>({
        data: {
            occurredAt: initialData.data.occurredAt,
            note: initialData.data.note,
            wellIDs: initialData.data.wellIDs,
            noteID: initialData.data.noteID,
        },
        validation: {
            note: undefined,
            occurredAt: undefined,
        },
        submitDisabled: true,
    })

    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        // handle change of note and update submitDisabled state
        const newFormManager = new WellNotesFormManager({
            ...formState.data,
            note: e.target.value,
        })

        // object to update formState
        const updatedFormState = {
            ...formState,
            data: newFormManager.formData,
            submitDisabled: newFormManager.getIsFormSubmitDisabled,
        }

        // update validation if there is a current note validation error (and it is being fixed) - else do not validate until blur
        if (formState.validation.note) {
            updatedFormState.validation.note =
                newFormManager.noteValidationState
        }

        setFormState(updatedFormState)
    }

    const handleOccurredAtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // handle change of occurredAt  and update submitDisabled state
        const newFormManager = new WellNotesFormManager({
            ...formState.data,
            occurredAt: e.target.value,
        })

        // obj to update formState
        const updatedFormState = {
            ...formState,
            data: newFormManager.formData,
            submitDisabled: newFormManager.getIsFormSubmitDisabled,
        }

        // update validation if there is a current occurred at validation error (and it is being fixed) - else do not validate until blur
        if (formState.validation.occurredAt) {
            updatedFormState.validation.occurredAt =
                newFormManager.occurredAtValidationState
        }

        setFormState(updatedFormState)
    }

    //TODO: handleWellIDsChange
    // const handleWellIDsChange = (wellIDs: string[]) => {
    //     console.log("changingWELLIDs", wellIDs)
    // }

    // blur handlers
    const handleOccurredAtBlur = () => {
        // check validaition of occurredAt and update submitDisabled state
        const newFormManager = new WellNotesFormManager({
            ...formState.data,
        })
        const submitDisabled = newFormManager.getIsFormSubmitDisabled
        setFormState({
            ...formState,
            submitDisabled: submitDisabled,
            validation: {
                ...formState.validation,
                occurredAt: newFormManager.occurredAtValidationState,
            },
        })
    }

    const handleNoteBlur = () => {
        // check validaition of note and update submitDisabled state
        const newFormManager = new WellNotesFormManager({
            ...formState.data,
        })
        const submitDisabled = newFormManager.getIsFormSubmitDisabled
        setFormState({
            ...formState,
            submitDisabled: submitDisabled,
            validation: {
                ...formState.validation,
                note: newFormManager.noteValidationState,
            },
        })
    }

    return {
        formState,
        setFormState,
        handleNoteChange,
        handleOccurredAtChange,
        handleOccurredAtBlur,
        handleNoteBlur,
    }
}

export default useWellNotesForm

/**
 * WellNotesFormManager handles the data, validation, and submission state
 * for the well notes form. Use in combination with useWellNotesForm hook.
 */

export class WellNotesFormManager {
    private readonly data: WellNotesFormData

    constructor(initialData: WellNotesFormData) {
        this.data = {
            note: initialData.note,
            occurredAt: initialData.occurredAt,
            wellIDs: initialData.wellIDs ?? [],
            noteID: initialData.noteID ?? "",
        }
    }

    // get form data
    public get formData(): WellNotesFormData {
        return { ...this.data }
    }

    // Get input for creating a note
    public getWellNotesCreateInput(): WellNotesCreateInput {
        const occurredAtUnixMs = new DateTimePickerValueManager(
            this.data.occurredAt
        ).getUnixMilliseconds()
        const note = this.data.note.trim()

        return {
            note: note,
            occurredAt: {
                unixMilliseconds: occurredAtUnixMs,
            },
            wellIDs: this.data.wellIDs as string[],
        }
    }

    // Get input for updating a note
    public getWellNotesUpdateInput(): WellNotesUpdateInput {
        const occurredAtUnixMs = new DateTimePickerValueManager(
            this.data.occurredAt
        ).getUnixMilliseconds()
        const note = this.data.note.trim()

        return {
            note: note,
            occurredAt: {
                unixMilliseconds: occurredAtUnixMs,
            },
            id: this.data.noteID as string,
        }
    }

    // validation helpers
    public get isFormValid(): boolean {
        // If any fields have validation errors, return false
        return !objectHasNonUndefinedFields({
            note: this.noteValidationState,
            occurredAt: this.occurredAtValidationState,
        })
    }

    public get getIsFormSubmitDisabled(): boolean {
        // Disable submit button if form is not valid
        return !this.isFormValid
    }

    public get occurredAtValidationState(): string | undefined {
        return this.data.occurredAt.length > 0
            ? undefined
            : "Occurred at is required"
    }

    public get noteValidationState(): string | undefined {
        return this.data.note.length > 0 ? undefined : "Note is required"
    }
}
