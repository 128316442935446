import { graphql } from "../../generated"

export const GET_NOTIFICATIONS_FOR_USER = graphql(`
    query GetNotificationsForUser(
        $NotificationsFilter: AlertNotificationsForUserInput
    ) {
        notificationsForUser(input: $NotificationsFilter) {
            notifications {
                ...NotficationFragMyNotifications
            }
        }
    }
`)
