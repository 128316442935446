import { FunctionComponent, useContext, useEffect, useState } from "react"
import styles from "./individualWellPage.module.scss"
import IwpSubNavigation from "../../components/individualWellPageComponents/subNavigation"
import { IWPChartData } from "../../components/individualWellPageComponents/wellDetailsChart"
import { ApolloError, useQuery } from "@apollo/client"
import { Outlet, useParams } from "react-router-dom"
import { getFragmentData } from "../../generated"
import { DATA_FRAME_FIELDS_FRAGMENT } from "../../graphql/fragments/dataFrame"
import {
    ControlIntentTimelineFieldsFragment,
    ControlObservationTimelineFieldsFragment,
    ControlSettingFieldsFragment,
    DataFrameFieldsFragment,
    DataPointFieldsFragment,
    EventType,
    GetControlSettingsHistoryQuery,
    GetNotificationsForUserQuery,
    InstallationFieldsFragment,
    NotficationFragMyNotificationsFragment,
    WellAlertFieldsFragment,
    WellControlObservationStateFieldsFragment,
    WellIdentificationFieldsFragment,
    ZoneInfo,
} from "../../generated/graphql"
import { getVariableDaysAgo_StartOfDayUnixMs } from "../../util/datesAndTimes/datesAndTimes"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../graphql/fragments/wellIdentification"
import { DATA_POINT_FIELDS_FRAGMENT } from "../../graphql/fragments/dataPoint"
import { WELL_ALERT_FIELDS_FRAGMENT } from "../../graphql/fragments/wellAlert"
import {
    WELL_CONTROL_OBSERVATION_STATE_FIELDS_FRAGMENT,
    WELL_CONTROL_STATE_FIELDS_FRAGMENT,
} from "../../graphql/fragments/wellState"
import { CONTROL_SETTING_FIELDS_FRAGMENT } from "../../graphql/fragments/wellControlSetting"
import StatusSideBar from "../../components/individualWellPageComponents/statusSideBar"
import classNames from "classnames"
import { WellAttributesComponentI } from "../../components/individualWellPageComponents/wellAttributes"
import { INSTALLATION_FIELDS_FRAGMENT } from "../../graphql/fragments/installation"
import {
    CONTROL_INTENT_TIMELINE_FRAGMENT,
    CONTROL_OBSERVATION_TIMELINE_FRAGMENT,
} from "../../graphql/fragments/eventTimeline"
import { useViewerContext } from "../../context/ViewerContext"
import { ConfigContext } from "../../context/ConfigContexts"
import { NEW_INDIVIDUAL_WELL_PAGE_QUERY } from "../../graphql/queries/newIdividualWellPage"
import { MOTION_TIMELINE_QUERY } from "../../graphql/queries/motionTimeline"
import { GET_CONTROL_SETTINGS_HISTORY } from "../../graphql/queries/controlSettingHistory"
import { GET_NOTIFICATIONS_FOR_USER } from "../../graphql/queries/notificationsForUser"
import { NOTIFICATION_FRAG_MY_NOTIFICATIONS } from "../../graphql/fragments/notifications"

export interface IwpOutletContextI {
    wellDetails: IWPChartData
    wellAttributes: WellAttributesComponentI
    alphaUser: boolean
    zoneInfo: ZoneInfo
    iwpLoading: boolean
    iwpMotionLoading: boolean
    iwpError?: ApolloError
    iwpMotionError?: ApolloError
    refetchMotionTimeline?: (from: number, to: number) => void
}

// These variables are exported so they can be used as the variables in refetchQueries for unlinking & linking modals
// 395 days = 1 year 1
export const iwpQueryParam_equipStatsFrom =
    getVariableDaysAgo_StartOfDayUnixMs(395)
export const iwpQueryParam_equipStatsTo = Date.now()
export const iwpQueryParam_historyCount = 4

export const equipmentMetricsToMS = Date.now()
// 390 days ago
export const equipmentMetricsFromMS =
    equipmentMetricsToMS - 390 * 24 * 60 * 60 * 1000

// just today
const iwpMotionToMS = Date.now()
const iwpMotionFromMS = iwpMotionToMS - 1 * 24 * 60 * 60 * 1000

const IndividualWellPage: FunctionComponent = () => {
    const { wellId } = useParams()
    if (!wellId) throw new Error("wellId not resolved from url.")

    /** UI State variables */
    const initialBrowserWidth = window.innerWidth
    const [displayWellDetailsChart, setDisplayWellDetailsChart] = useState(true)
    const [displayStatusSideBar, setDisplayStatusSideBar] = useState(
        initialBrowserWidth > 992 ? true : false
    )
    const [windowSize, setWindowSize] = useState({
        width: initialBrowserWidth,
    })

    /** Contexts */
    const config = useContext(ConfigContext)
    const viewer = useViewerContext()

    /** Alpha user check */
    const alphaUser = config.alphaTestersUserIds.includes(
        viewer.getViewer().userId
    )

    /** Original query variables */
    const iwpQueryVariables = {
        wellInput: { id: wellId },
        historyCount: iwpQueryParam_historyCount,
        zoneInfo: viewer.getViewer().identity.zoneInfo,
        wellControlInput: { wellID: wellId },
        equipmentMetricsIntervalInput: {
            from: {
                unixMilliseconds: equipmentMetricsFromMS,
            },
            to: {
                unixMilliseconds: equipmentMetricsToMS,
            },
        },
        equipmentMetricsRollupDurationInput: {
            hours: 24,
        },
    }

    /** Motion query variables */
    const iwpMotionQueryVariables = {
        motionTimelineInput: {
            wellID: wellId,
            interval: {
                from: {
                    unixMilliseconds: iwpMotionFromMS,
                },
                to: {
                    unixMilliseconds: iwpMotionToMS,
                },
            },
            eventType: EventType.ControlObservation,
            motionOptions: {
                supplement: true,
                debounce: true,
            },
        },
        intentTimelineInput: {
            wellID: wellId,
            interval: {
                from: {
                    unixMilliseconds: iwpMotionFromMS,
                },
                to: {
                    unixMilliseconds: iwpMotionToMS,
                },
            },
            eventType: EventType.ControlIntent,
        },
    }

    /** Query old individual well */
    const { error, data, loading } = useQuery(NEW_INDIVIDUAL_WELL_PAGE_QUERY, {
        variables: iwpQueryVariables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    })

    const iwpQueryError = error
    const iwpQueryData = data
    const iwpQueryLoading = loading

    const {
        error: motionError,
        data: motionData,
        loading: motionLoading,
        refetch,
    } = useQuery(MOTION_TIMELINE_QUERY, {
        variables: iwpMotionQueryVariables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
    })

    const iwpMotionQueryError = motionError
    const iwpMotionQueryData = motionData
    const iwpMotionQueryLoading = motionLoading
    const iwpMotionRefetch = async (from: number, to: number) => {
        await refetch({
            motionTimelineInput: {
                ...iwpMotionQueryVariables.motionTimelineInput,
                interval: {
                    from: {
                        unixMilliseconds: from,
                    },
                    to: {
                        unixMilliseconds: to,
                    },
                },
            },
            intentTimelineInput: {
                ...iwpMotionQueryVariables.intentTimelineInput,
                interval: {
                    from: {
                        unixMilliseconds: from,
                    },
                    to: {
                        unixMilliseconds: to,
                    },
                },
            },
        })
    }

    let iwpControlSettingsQueryError: ApolloError | undefined = undefined
    let iwpControlSettingsQueryData:
        | GetControlSettingsHistoryQuery
        | undefined = undefined
    let iwpControlSettingsQueryLoading = false

    let iwpNotificationsQueryError: ApolloError | undefined = undefined
    let iwpNotificationsQueryData: GetNotificationsForUserQuery | undefined =
        undefined
    let iwpNotificationsQueryLoading = false

    if (alphaUser) {
        const {
            error: controlSettingError,
            data: controlSettingData,
            loading: controlSettingLoading,
        } = useQuery(GET_CONTROL_SETTINGS_HISTORY, {
            variables: {
                wellControlInput: { wellID: wellId },
                historyCount: 100,
                wellInput: { id: wellId },
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
        })

        iwpControlSettingsQueryError = controlSettingError
        iwpControlSettingsQueryData = controlSettingData
        iwpControlSettingsQueryLoading = controlSettingLoading

        const {
            error: notificationsError,
            data: notificationsData,
            loading: notificationsLoading,
        } = useQuery(GET_NOTIFICATIONS_FOR_USER, {
            variables: {
                NotificationsFilter: {
                    resolvedHorizon: {
                        unixMilliseconds: equipmentMetricsFromMS,
                    },
                    filter: {
                        wellIDs: [wellId],
                    },
                },
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
        })

        iwpNotificationsQueryError = notificationsError
        iwpNotificationsQueryData = notificationsData
        iwpNotificationsQueryLoading = notificationsLoading
    }

    /** Parse Data fragments to pass to components */
    let runTimeDataFrame: DataFrameFieldsFragment | undefined = undefined
    let wellIdentificationfragment:
        | WellIdentificationFieldsFragment
        | undefined = undefined
    let runTimeDaily: DataFrameFieldsFragment | undefined = undefined
    let latestSpmDataPoint: DataPointFieldsFragment | undefined = undefined
    let wellAlertFragment: WellAlertFieldsFragment | undefined | null =
        undefined
    let observationFragment:
        | WellControlObservationStateFieldsFragment
        | undefined = undefined

    let controlSettingFragment: ControlSettingFieldsFragment | undefined =
        undefined
    let installationFragment: InstallationFieldsFragment | null | undefined
    let controlIntentTimelineFragments:
        | ControlIntentTimelineFieldsFragment[]
        | undefined

    let controlObservationTimelineFragments:
        | ControlObservationTimelineFieldsFragment[]
        | undefined

    const controlSettingsFragment: ControlSettingFieldsFragment[] = []
    const notificationsFragment: NotficationFragMyNotificationsFragment[] = []

    if (iwpQueryData && iwpQueryData.well) {
        const { well } = iwpQueryData
        /** Parse Fragments to pass to children components */
        runTimeDataFrame = getFragmentData(
            DATA_FRAME_FIELDS_FRAGMENT,
            well.equipmentMetrics.windowedRunTime
        )

        wellIdentificationfragment = getFragmentData(
            WELL_IDENTIFICATION_FIELDS_FRAGMENT,
            well
        )
        runTimeDaily = getFragmentData(
            DATA_FRAME_FIELDS_FRAGMENT,
            well.equipmentMetrics.dailyRunTime
        )

        latestSpmDataPoint = getFragmentData(
            DATA_POINT_FIELDS_FRAGMENT,
            well.equipmentMetrics.latestSpm
        )
        wellAlertFragment = getFragmentData(
            WELL_ALERT_FIELDS_FRAGMENT,
            well.alert
        )
        const WellControlStateFieldsFragment = getFragmentData(
            WELL_CONTROL_STATE_FIELDS_FRAGMENT,
            well.state
        )
        observationFragment = getFragmentData(
            WELL_CONTROL_OBSERVATION_STATE_FIELDS_FRAGMENT,
            WellControlStateFieldsFragment.observation
        )
        controlSettingFragment = getFragmentData(
            CONTROL_SETTING_FIELDS_FRAGMENT,
            well.controlSetting
        )
        installationFragment = getFragmentData(
            INSTALLATION_FIELDS_FRAGMENT,
            well.installation
        )
    }

    if (iwpNotificationsQueryData) {
        iwpNotificationsQueryData.notificationsForUser.notifications.forEach(
            (notification) => {
                const got = getFragmentData(
                    NOTIFICATION_FRAG_MY_NOTIFICATIONS,
                    notification
                )
                notificationsFragment.push(got)
            }
        )
    }

    if (iwpControlSettingsQueryData) {
        iwpControlSettingsQueryData.controlHistory.nodes?.forEach((cs) => {
            const got = getFragmentData(CONTROL_SETTING_FIELDS_FRAGMENT, cs)
            controlSettingsFragment.push(got)
        })
    }

    if (iwpMotionQueryData) {
        iwpMotionQueryData.intentTimeline.series.timeline.forEach((event) => {
            if (event.__typename == "WellControlIntentTimelineEvent") {
                const got = getFragmentData(
                    CONTROL_INTENT_TIMELINE_FRAGMENT,
                    event
                )
                if (controlIntentTimelineFragments) {
                    controlIntentTimelineFragments.push(got)
                } else {
                    controlIntentTimelineFragments = [got]
                }
            }
        })

        iwpMotionQueryData.motionTimeline.series.timeline.forEach((event) => {
            if (event.__typename == "WellControlObservationTimelineEvent") {
                const got = getFragmentData(
                    CONTROL_OBSERVATION_TIMELINE_FRAGMENT,
                    event
                )
                if (controlObservationTimelineFragments) {
                    controlObservationTimelineFragments.push(got)
                } else {
                    controlObservationTimelineFragments = [got]
                }
            }
        })
    }

    /** Effect hooks */
    useEffect(() => {
        if (windowSize.width > 992) {
            setDisplayWellDetailsChart(true)
        }
        if (windowSize.width < 992 && displayWellDetailsChart) {
            setDisplayStatusSideBar(false)
        }
    }, [windowSize.width])

    useEffect(() => {
        const handleWindowWidthChange = () => {
            setWindowSize({
                width: window.innerWidth,
            })
        }

        window.addEventListener("resize", handleWindowWidthChange)

        return () => {
            window.removeEventListener("resize", handleWindowWidthChange)
        }
    }, [])

    /** Handlers */
    const handleToggleStatusSideBar = () => {
        if (windowSize.width > 992) {
            setDisplayStatusSideBar(!displayStatusSideBar)
            setDisplayWellDetailsChart(true)
        }
        if (windowSize.width < 992) {
            const currentSideBarStatus = displayStatusSideBar
            setDisplayStatusSideBar(!currentSideBarStatus)
            setDisplayWellDetailsChart(currentSideBarStatus)
        }
    }

    return (
        <>
            <div className={styles.container}>
                <IwpSubNavigation
                    toogleStatusBar={handleToggleStatusSideBar}
                    wellIdentificationfragment={wellIdentificationfragment}
                    windowWidth={windowSize.width}
                />
                <div className={styles.bodyContainer}>
                    {displayStatusSideBar && (
                        <div className={styles.sidebarContainer}>
                            <StatusSideBar
                                loading={
                                    iwpQueryLoading ||
                                    iwpMotionQueryLoading ||
                                    iwpNotificationsQueryLoading ||
                                    iwpControlSettingsQueryLoading
                                }
                                error={
                                    iwpQueryError ||
                                    iwpMotionQueryError ||
                                    iwpNotificationsQueryError ||
                                    iwpControlSettingsQueryError
                                }
                                wellIdentificationFragment={
                                    wellIdentificationfragment
                                }
                                runTimeDaily={runTimeDaily}
                                latestSpmDataPoint={latestSpmDataPoint}
                                wellAlertFragment={wellAlertFragment}
                                observationFragment={observationFragment}
                                controlSetting={controlSettingFragment}
                            />
                        </div>
                    )}
                    {displayWellDetailsChart && (
                        <div
                            className={classNames(
                                styles.iwpOutletContainer,
                                displayStatusSideBar &&
                                    styles.iwpSideBarContainerActive
                            )}
                        >
                            <Outlet
                                context={
                                    {
                                        wellDetails: {
                                            runTime: runTimeDataFrame,
                                            intentTimeline:
                                                controlIntentTimelineFragments,
                                            observationTimeline:
                                                controlObservationTimelineFragments,
                                            controlSettings:
                                                controlSettingsFragment,
                                            notification: notificationsFragment,
                                        },
                                        wellAttributes: {
                                            wellIdentificationFragment:
                                                wellIdentificationfragment,
                                            installationFragment:
                                                installationFragment,
                                            windowWidth: windowSize.width,
                                        },
                                        alphaUser: alphaUser,
                                        zoneInfo:
                                            viewer.getViewer().identity
                                                .zoneInfo,
                                        iwpLoading: iwpQueryLoading,
                                        iwpMotionLoading:
                                            iwpMotionQueryLoading || false,
                                        iwpError: iwpQueryError,
                                        iwpMotionError: iwpMotionQueryError,
                                        refetchMotionTimeline: iwpMotionRefetch,
                                    } satisfies IwpOutletContextI
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default IndividualWellPage
