import { graphql } from "../../generated"

export const WELL_NOTES_FOR_IWP = graphql(`
    query GetWellNotesForIWP($input: WellNotesByWellIDsInput!) {
        wellNotesByWellIDs(input: $input) {
            wellNotes {
                ...WellNotesForIwpFields
            }
        }
    }
`)

export const WELL_NOTES_FOR_MODALS = graphql(`
    query GetWellNotesCrudModal($input: WellNotesByIDsInput!) {
        wellNotesByIDs(input: $input) {
            wellNotes {
                ...WellNotesForModalsFields
            }
        }
    }
`)
