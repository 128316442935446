import { FunctionComponent, useEffect } from "react"
import ModalHeader from "../../../shared/modalHeader"
import { TrexNavigator } from "../../../../classes/navigator/navigator"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalFooter from "../../../shared/modalFooter"
import WellNotesFormComp from "../wellNotesForm/component"
import { useMutation, useQuery } from "@apollo/client"
import { WELL_NOTES_FOR_MODALS } from "../../../../graphql/queries/wellNotes"
import useWellNotesForm, { WellNotesFormManager } from "../wellNotesForm/form"
import { WELL_NOTES_UPDATE } from "../../../../graphql/mutations/well_notes"
import styles from "./update.module.scss"
import { WELL_NOTES_FOR_MODALS_FRAG } from "../../../../graphql/fragments/wellnote"
import { getFragmentData } from "../../../../generated"
import Button from "../../../shared/button"
import {
    WELL_NOTES_ABS_ROUTE,
    WELL_NOTES_DELETE_CHILD_ROUTE,
} from "../../../.."
import dayjs from "dayjs"

const WellNotesUpdateModal: FunctionComponent = () => {
    // hooks
    const navigate = useNavigate()
    const { key: locationKey, pathname } = useLocation()
    const { wellId, noteId } = useParams()
    if (!wellId || !noteId) {
        throw new Error("wellId or noteId not resolved from url.")
    }

    // get well note by id
    const {
        error: queryError,
        loading: queryLoading,
        data: queryData,
    } = useQuery(WELL_NOTES_FOR_MODALS, {
        variables: {
            input: { ids: [noteId] },
        },
        fetchPolicy: "network-only",
    })

    // mutations
    const [updateWellNote, { loading: updateLoading, error: updateError }] =
        useMutation(WELL_NOTES_UPDATE)

    // form state and handlers
    const {
        formState,
        setFormState,
        handleNoteChange,
        handleOccurredAtChange,
        handleNoteBlur,
        handleOccurredAtBlur,
    } = useWellNotesForm({
        data: {
            note: "",
            occurredAt: "",
            wellIDs: [wellId],
            noteID: noteId,
        },
        submitDisabled: true,
        validation: {
            note: "",
            occurredAt: "",
        },
    })

    // submission handler
    const handleSubmitUpdateNote = async () => {
        const formManager = new WellNotesFormManager(formState.data)
        // if the form is not valid - set updated state and do not submit by returning
        // our class and form state prevent submit being called in a not valid state - this is a safety net
        if (!formManager.isFormValid) {
            setFormState({
                data: formManager.formData,
                submitDisabled: true,
                validation: {
                    note: formManager.noteValidationState,
                    occurredAt: formManager.occurredAtValidationState,
                },
            })
            return
        }
        // if form is valid upate well note note using the mutation
        const updateVariables = formManager.getWellNotesUpdateInput()
        await updateWellNote({
            variables: { WellNotesUpdateInput: updateVariables },
        })
            .then(() => {
                navigate(`${WELL_NOTES_ABS_ROUTE(wellId)}`)
            })
            .catch((error) => {
                // mutation error will be passed to the form from the mutation variables assigned above. This error is passed to the form for error handling
                console.error("Error updating well note:", error)
            })
    }

    // set form data after it has loaded
    useEffect(() => {
        if (queryData && queryData.wellNotesByIDs.wellNotes.length > 0) {
            const wellNote = getFragmentData(
                WELL_NOTES_FOR_MODALS_FRAG,
                queryData.wellNotesByIDs.wellNotes[0]
            )
            setFormState({
                data: {
                    ...formState.data,
                    note: wellNote.note,
                    // convert unix seconds to date string in local time for the DateAndTimePicker
                    occurredAt: dayjs
                        .unix(wellNote.occurredAt.unixSeconds)
                        .format("YYYY-MM-DDTHH:mm"),
                },
                submitDisabled: true,
                validation: {
                    note: "",
                    occurredAt: "",
                },
            })
        }
    }, [queryData])

    return (
        <div className={styles.container}>
            <ModalHeader
                title="Update Well Note"
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: pathname,
                        },
                        navigate
                    )
                }
            />
            <WellNotesFormComp
                handleNoteChange={handleNoteChange}
                handleOccurredAtChange={handleOccurredAtChange}
                handleOccurredAtBlur={handleOccurredAtBlur}
                handleNoteBlur={handleNoteBlur}
                formState={formState}
                loading={updateLoading || queryLoading}
                fetchError={queryError}
                mutationError={updateError}
                formType="update"
            />
            <div className={styles.deleteContainer}>
                <div className={styles.deleteButton}>
                    <Button
                        status="secondary"
                        condition="alert"
                        handleButtonClick={() => {
                            navigate(
                                `${WELL_NOTES_ABS_ROUTE(
                                    wellId
                                )}/${WELL_NOTES_DELETE_CHILD_ROUTE(noteId)}`
                            )
                        }}
                        disabled={
                            updateLoading ||
                            queryLoading ||
                            Boolean(queryError) ||
                            Boolean(updateError)
                        }
                    >
                        Delete Well Note
                    </Button>
                </div>
            </div>
            <ModalFooter
                advanceText="Update Note"
                disableAdvance={
                    updateLoading ||
                    queryLoading ||
                    Boolean(updateError) ||
                    Boolean(queryError) ||
                    formState.submitDisabled
                }
                handleAdvanceClick={handleSubmitUpdateNote}
            />
        </div>
    )
}

export default WellNotesUpdateModal
