import { FunctionComponent, ReactNode } from "react"
import { ApolloError, useMutation, useQuery } from "@apollo/client"
import { WELL_NOTES_DELETE } from "../../../../graphql/mutations/well_notes"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import styles from "./delete.module.scss"
import {
    WELL_NOTES_FOR_IWP,
    WELL_NOTES_FOR_MODALS,
} from "../../../../graphql/queries/wellNotes"
import ModalHeader from "../../../shared/modalHeader"
import { WELL_NOTES_ABS_ROUTE } from "../../../.."
import { getFragmentData } from "../../../../generated"
import { WELL_NOTES_FOR_MODALS_FRAG } from "../../../../graphql/fragments/wellnote"
import {
    EmptyState,
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../shared/graphQlResponse"
import dayjs from "dayjs"
import ModalFooter from "../../../shared/modalFooter"
import { TrexNavigator } from "../../../../classes/navigator/navigator"

const WellNotesDeleteModal: FunctionComponent = () => {
    // hooks
    const navigate = useNavigate()
    const { wellId, noteId } = useParams()
    const { key: locationKey } = useLocation()
    if (!wellId || !noteId) {
        throw new Error("wellId or noteId not resolved from url.")
    }

    // query
    const { error, loading, data } = useQuery(WELL_NOTES_FOR_MODALS, {
        variables: {
            input: { ids: [noteId] },
        },
        fetchPolicy: "network-only",
    })

    // mutation
    const [
        deleteWellNote,
        { loading: mutationLoading, error: deleteWellNoteError },
    ] = useMutation(WELL_NOTES_DELETE)

    // submission handler
    const handleDeleteNote = async () => {
        await deleteWellNote({
            variables: { WellNotesDeleteInput: { ids: [noteId] } },
            refetchQueries: [WELL_NOTES_FOR_IWP],
        })
            .then(() => {
                navigate(WELL_NOTES_ABS_ROUTE(wellId))
            })
            .catch((e: ApolloError) => {
                console.error("Error deleting well note:", e)
            })
    }

    // well note
    const w = data?.wellNotesByIDs.wellNotes[0]
    const wellNote = getFragmentData(WELL_NOTES_FOR_MODALS_FRAG, w)

    // constants
    const isSubmitDisabled =
        loading ||
        Boolean(error) ||
        mutationLoading ||
        Boolean(deleteWellNoteError) ||
        !wellNote

    // rendering
    let deleteWellNoteComponent: ReactNode

    if (loading) {
        deleteWellNoteComponent = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    } else if (error) {
        deleteWellNoteComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="We had a problem while fetching your well note."
            />
        )
    } else if (deleteWellNoteError) {
        deleteWellNoteComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="There was a problem deleting your well note."
            />
        )
    } else if (!wellNote) {
        deleteWellNoteComponent = (
            <EmptyState outSideDivStyle={styles.queryEmpty}>
                <> No well note with this id was found.</>
            </EmptyState>
        )
    } else if (wellNote) {
        deleteWellNoteComponent = (
            <>
                <div className={styles.title}>
                    Are you sure you want to delete this well note?
                </div>
                <div className={styles.description}>
                    <div className={styles.marginBottom8}>
                        <span className={styles.label}>Occurred at:</span>
                        <span>
                            {dayjs
                                .unix(wellNote.occurredAt.unixSeconds)
                                .format("MMM D, YYYY h:mm A")}
                        </span>
                    </div>
                    <div>
                        <span className={styles.label}>Description:</span>
                        <span> {wellNote.note}</span>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                title={"Delete Well Note"}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            <div className={styles.body}>{deleteWellNoteComponent}</div>
            <ModalFooter
                advanceText="Confirm"
                handleAdvanceClick={handleDeleteNote}
                disableAdvance={isSubmitDisabled}
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(-1)
                }}
                disablePrevious={isSubmitDisabled}
            />
        </div>
    )
}

export default WellNotesDeleteModal
