import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import HighStock from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import styles from "./wellDetailsChart.module.scss"
import classNames from "classnames"
import { SyncLoader } from "react-spinners"
import Boost from "highcharts/modules/boost"
import XRangeModule from "highcharts/modules/xrange"
import {
    ControlIntentTimelineFieldsFragment,
    ControlObservationTimelineFieldsFragment,
    ControlSettingFieldsFragment,
    DataFrameFieldsFragment,
    NotficationFragMyNotificationsFragment,
} from "../../../generated/graphql"
import { cloneDeep } from "lodash"
import {
    convertToZipcelxFormat,
    valuesInsideChartView,
    updateIncomingRunTimeForWellDetailsLineChart,
    getXrangePointsInView,
    getHighChartsDataInView,
    updateIncomingControlForWellDetailsLineChart,
    updateIncomingIdleTimeForWellDetailsLineChart,
    updateIncomingUnknownTimeForWellDetailsLineChart,
    getAggregationUnits,
    convertNewRuntimeToCSV,
    convertWellMotionToCSV,
    convertNewRuntimeToCelx,
} from "./wellDetailsChartUtils"
import { wellMotionChartOptions, wellRunTimeChartOptions } from "./chartOptions"
import Exporting from "highcharts/modules/exporting"
import ExportData from "highcharts/modules/export-data"
import zipcelx from "zipcelx"
import {
    convertSecondsToHoursAndMinutes,
    formatTimestampWithTimezone,
    getVariableDaysAgo_StartOfDayUnixMs,
} from "../../../util/datesAndTimes/datesAndTimes"
import { Outlet, useOutletContext, useSearchParams } from "react-router-dom"
import { DateIntervalType } from "./wellDetailsChartType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons"
import { IwpOutletContextI } from "../../../pages/individualWellPage"
import Input from "../../shared/input"
import { formatTimestampsToDuration } from "../../../util/duration/duration"
import { getZoneInfoFromEnum } from "../../../graphql/enum/zoneInfo"
import ToolTip from "../../shared/toolTip/tooltip"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import UTC from "dayjs/plugin/utc"
import {
    FlagsFromControlSettings,
    FlagsFromNotifications,
} from "./iwpAnnotations"

/** Apply dayjs module */
dayjs.extend(UTC)
dayjs.extend(timezone)

/** Apply highcharts modules */
Exporting(HighStock)
ExportData(HighStock)
Boost(HighStock)
XRangeModule(HighStock)

/** Define and export the chart data interface */
export interface IWPChartData {
    runTime: DataFrameFieldsFragment | undefined
    intentTimeline: ControlIntentTimelineFieldsFragment[] | undefined
    observationTimeline: ControlObservationTimelineFieldsFragment[] | undefined
    controlSettings: ControlSettingFieldsFragment[] | undefined
    notification: NotficationFragMyNotificationsFragment[] | undefined
}

const IWPCharts: FunctionComponent = () => {
    const IWPContext = useOutletContext()

    /** Get context values */
    const {
        alphaUser,
        zoneInfo,
        iwpLoading,
        iwpMotionLoading,
        iwpError,
        iwpMotionError,
        refetchMotionTimeline,
        wellDetails: {
            runTime,
            intentTimeline,
            observationTimeline,
            controlSettings,
            notification,
        },
        wellAttributes: { wellIdentificationFragment },
    } = IWPContext as IwpOutletContextI

    /** URL Params */
    const [searchParams, setSearchParams] = useSearchParams()
    const setURLParams = (URLparam: string, value: string) => {
        const updatedParams = new URLSearchParams(window.location.search)
        updatedParams.set(URLparam, value)
        setSearchParams(updatedParams, {
            replace: true,
        })
    }

    /** Define all states */
    const [runtimeOptions, setRuntimeOptions] = useState<HighStock.Options>(
        wellRunTimeChartOptions
    )
    const [motionOptions, setMotionOptions] = useState<HighStock.Options>(
        wellMotionChartOptions
    )
    const [runtimeSeries, setRuntimeSeries] = useState<
        HighStock.SeriesOptionsType[]
    >([])
    const [motionSeries, setMotionSeries] = useState<
        HighStock.SeriesOptionsType[]
    >([])

    /** Motion Date */
    const [motionDate, setMotionDate] = useState<Date | undefined>(undefined)
    const setMotionDateAndURL = (date: Date) => {
        setMotionDate(date)
        setURLParams("motionDate", date.getTime().toString())
    }

    const [rollupInterval, setRollupInterval] = useState<DateIntervalType>(
        (searchParams.get("dateInterval") as DateIntervalType) || "day"
    )
    const [dataInView, setDataInView] = useState<boolean>(true)

    /** Refs */
    const rollupIntervalRef = useRef(rollupInterval)
    const runtimeChartRef = useRef<HighStock.Chart | null>(null)
    const motionChartRef = useRef<HighStock.Chart | null>(null)
    const afterSetExtremeCount = useRef(0)

    /** Handlers */
    const setMotionDateFromTimestamp = (timestamp: number) => {
        // Convert timestamp to start of day in the selected timezone
        const target = dayjs(timestamp)
            .tz(getZoneInfoFromEnum(zoneInfo))
            .startOf("day")
        setMotionDateAndURL(target.toDate())
    }
    const pointClickHandler = (point: HighStock.Point): void => {
        setMotionDateFromTimestamp(point.x)
    }

    const handleRollupIntervalChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { value } = e.target
        if (runtimeOptions.series) {
            const { selected, chartAggregationUnits } = getAggregationUnits(
                value as DateIntervalType
            )

            if (selected != "day") {
                runtimeOptions.series.forEach((series, idx) => {
                    if (idx > 2) {
                        series.showInLegend = false
                        series.visible = false
                    }
                })
            } else {
                runtimeOptions.series.forEach((series, idx) => {
                    if (idx > 2) {
                        series.showInLegend = true
                        series.visible = true
                    }
                })
            }

            const updateOptions: HighStock.Options = {
                ...runtimeOptions,
                xAxis: runtimeOptions.xAxis,
                time: {
                    ...runtimeOptions.time,
                    timezone: getZoneInfoFromEnum(zoneInfo),
                },
                plotOptions: {
                    ...runtimeOptions.plotOptions,
                    series: {
                        ...runtimeOptions.plotOptions?.series,
                        dataGrouping: {
                            enabled: true,
                            forced: true,
                            units: chartAggregationUnits,
                        },
                    },
                },
            }

            setRuntimeOptions(updateOptions)
            setURLParams("dateInterval", selected)
            setRollupInterval(selected)
            rollupIntervalRef.current = selected
        }
    }

    /** useEffects */
    /** Handle initial chart setup and data grouping */
    useEffect(() => {
        if (!runTime?.highchartSeries?.length) return

        const dataGroupingUnits: [string, number[] | null][] = rollupInterval
            ? getAggregationUnits(rollupInterval).chartAggregationUnits
            : [
                  ["day", [1]],
                  ["week", [1]],
                  ["month", [1]],
              ]

        const updatedRuntimeOptions: HighStock.Options = {
            ...runtimeOptions,
            legend: {
                useHTML: true,
                // labelFormatter: function () {
                //     // normalize name for class name
                //     const legendClass = this.name
                //         .replace(/ /g, "-")
                //         .toLowerCase()
                //     if (
                //         ["Run Time", "Idle Time", "Not Available"].includes(
                //             this.name
                //         )
                //     ) {
                //         return `<div class="${styles[legendClass]} legend-item">${this.name}</div>`
                //     }
                //     // Add annotation className to the legend
                //     return `<div class="${styles[legendClass]} ${styles["annotation-item"]}">${this.name}</div>`
                // },
            },
            xAxis: {
                ...runtimeOptions.xAxis,
                events: {
                    afterSetExtremes: function (this, event) {
                        const { min, max } = event
                        const isValueInView = valuesInsideChartView(this.series)
                        if (isValueInView) {
                            setDataInView(true)
                        } else {
                            setDataInView(false)
                        }

                        /**
                         *  if afterSetExtremeCount === 0, user has rendered the page via the url
                         *  if the user has used query parameters, the extremes will be updated in the load function
                         *  if there are no query parameters, query parameters will not be populated upon initial reneder.
                         *  once the user updates the chart via the web componet, parameters will be populated to the url.
                         *  This causes performance issues as the url is set for every movement on the chart.
                         */
                        if (afterSetExtremeCount.current > 0) {
                            // user is updating highchart from web
                            setURLParams("from", min.toString())
                            setURLParams("to", max.toString())

                            afterSetExtremeCount.current++
                            return
                        }
                        afterSetExtremeCount.current++
                    },
                },
            },
            time: {
                ...runtimeOptions.time,
                timezone: getZoneInfoFromEnum(zoneInfo),
            },
            plotOptions: {
                ...runtimeOptions.plotOptions,
                series: {
                    ...runtimeOptions.plotOptions?.series,
                    dataGrouping: {
                        enabled: true,
                        forced: true,
                        units: dataGroupingUnits,
                        approximation: "average",
                        groupPixelWidth: 100,
                    },
                },
            },
        }

        setRuntimeOptions(updatedRuntimeOptions)
    }, [runTime])

    /** Apply timezone when it's available */
    useEffect(() => {
        let initialMotionDate = dayjs()
            .tz(getZoneInfoFromEnum(zoneInfo))
            .startOf("day")
        if (searchParams.has("motionDate")) {
            initialMotionDate = dayjs(
                parseInt(searchParams.get("motionDate") as string)
            )
        }

        setMotionDateAndURL(initialMotionDate.toDate())
    }, [zoneInfo])

    useEffect(() => {
        const fromURLTimestamp = parseInt(
            searchParams.get("from") ||
                getVariableDaysAgo_StartOfDayUnixMs(31).toString()
        )
        const toURLTimesetamp = parseInt(
            searchParams.get("to") || new Date().getTime().toString()
        )

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const chart = (runtimeChartRef as any).current?.chart
        if (chart) {
            chart.xAxis[0].setExtremes(fromURLTimestamp, toURLTimesetamp)
        }
    }, [runtimeOptions])

    /** convert incoming runtime data to series */
    useEffect(() => {
        const convertedRuntimeData =
            updateIncomingRunTimeForWellDetailsLineChart(
                cloneDeep(runTime?.highchartSeries)
            )

        const convertedIdleTimeData =
            updateIncomingIdleTimeForWellDetailsLineChart(
                cloneDeep(runTime?.highchartSeries)
            )

        const convertedUnknownTimeData =
            updateIncomingUnknownTimeForWellDetailsLineChart(
                cloneDeep(runTime?.highchartSeries)
            )

        const runtimeSeries: HighStock.SeriesOptionsType[] = [
            {
                type: "column",
                data: convertedIdleTimeData,
                id: "Idle Time",
                name: "Idle Time",
                yAxis: 0,
                color: "#0398FC",
                point: {
                    events: {
                        click: function (this) {
                            pointClickHandler(this)
                        },
                    },
                },
                legendIndex: 1,
            },
            {
                type: "column",
                data: convertedUnknownTimeData,
                id: "Unknown Time",
                name: "Not Available",
                yAxis: 0,
                color: "#BF42F5",
                point: {
                    events: {
                        click: function (this) {
                            pointClickHandler(this)
                        },
                    },
                },
                legendIndex: 2,
            },
            {
                type: "column",
                data: convertedRuntimeData,
                id: "Run Time",
                name: "Run Time",
                yAxis: 0,
                color: "#02C944",
                point: {
                    events: {
                        click: function (this) {
                            pointClickHandler(this)
                        },
                    },
                },
                legendIndex: 0,
            },
        ]

        if (alphaUser) {
            if (controlSettings) {
                const controlSettingFlags = FlagsFromControlSettings(
                    controlSettings,
                    zoneInfo,
                    runTime?.index[0]
                )
                runtimeSeries.push(controlSettingFlags.runtimeAnnotations)
            }

            if (notification) {
                const notificationFlags = FlagsFromNotifications(
                    notification,
                    zoneInfo
                )

                runtimeSeries.push(notificationFlags.runtimeAnnotations)
            }
        }

        setRuntimeSeries(runtimeSeries)
    }, [runTime, controlSettings, notification])

    /** Convert motion data to series */
    useEffect(() => {
        const convertedIntentData =
            updateIncomingControlForWellDetailsLineChart(
                cloneDeep(intentTimeline)
            )

        const convertedObservationData =
            updateIncomingControlForWellDetailsLineChart(
                cloneDeep(observationTimeline)
            )

        const motionSeries: HighStock.SeriesOptionsType[] = [
            {
                type: "xrange",
                data: convertedIntentData,
                name: "Intent",
                color: "#FF9800",
                turboThreshold: 0,
            },
            {
                type: "xrange",
                data: convertedObservationData,
                name: "Observation",
                color: "#02C944",
                turboThreshold: 0,
            },
        ]

        if (alphaUser) {
            if (controlSettings) {
                const flags = FlagsFromControlSettings(
                    controlSettings,
                    zoneInfo,
                    runTime?.index[0]
                )
                motionSeries.push(flags.motionAnnotations)
            }

            if (notification) {
                const flags = FlagsFromNotifications(notification, zoneInfo)
                motionSeries.push(flags.motionAnnotations)
            }
        }

        setMotionSeries(motionSeries)
    }, [intentTimeline, observationTimeline, controlSettings, notification])

    useEffect(() => {
        const wellName =
            wellIdentificationFragment?.name.replace(/[^a-zA-Z0-9\-_]/g, "_") ||
            "chart"

        const updatedRuntimeOptions: HighStock.Options = {
            ...runtimeOptions,
            tooltip: {
                ...runtimeOptions.tooltip,
                formatter: function () {
                    const toolTipArray: string[] = []
                    const tz = getZoneInfoFromEnum(zoneInfo)
                    const localTime = dayjs(this.x).tz(tz).format("MMM D, YYYY")

                    if (
                        ["Notifications", "Control Settings"].includes(
                            this.series.name
                        )
                    ) {
                        const thisPoint = this
                            .point as HighStock.PointOptionsObject

                        toolTipArray.push(`${localTime}<br/>`)
                        const text = thisPoint.text || ""
                        toolTipArray.push(text)
                        return toolTipArray
                    }

                    toolTipArray.push(localTime)
                    const points = this.points

                    if (points) {
                        points.forEach((point) => {
                            if (
                                ["Run Time", "Idle Time"].includes(
                                    this.series.name
                                )
                            ) {
                                if (point.y || point.y === 0) {
                                    const { hours, minutes } =
                                        convertSecondsToHoursAndMinutes(point.y)

                                    toolTipArray.push(
                                        `${point.series.name}: ${hours}h ${minutes}m`
                                    )
                                }
                            } else {
                                toolTipArray.push(point.point.name)
                            }
                        })
                    }

                    return toolTipArray
                },
            },
            time: {
                ...runtimeOptions.time,
                timezone: getZoneInfoFromEnum(zoneInfo),
            },
            xAxis: runtimeOptions.xAxis,
            series: runtimeSeries,
            exporting: {
                ...runtimeOptions.exporting,
                filename: wellName,
            },
        }
        setRuntimeOptions(updatedRuntimeOptions)
    }, [runtimeSeries])

    /** Update options when series data changes */
    useEffect(() => {
        const wellName =
            wellIdentificationFragment?.name.replace(/[^a-zA-Z0-9\-_]/g, "_") ||
            "chart"

        const updatedMotionOptions: HighStock.Options = {
            ...motionOptions,
            time: {
                ...motionOptions.time,
                timezone: getZoneInfoFromEnum(zoneInfo),
            },
            tooltip: {
                ...motionOptions.tooltip,
                stickOnContact: true,
                formatter: function () {
                    if (
                        ["Notifications", "Control Settings"].includes(
                            this.series.name
                        )
                    ) {
                        const thisPoint = this
                            .point as HighStock.PointOptionsObject
                        const tooltipArray: string[] = []
                        const tz = getZoneInfoFromEnum(zoneInfo)
                        const localTime = dayjs(this.x)
                            .tz(tz)
                            .format("MMM D, YYYY h:mm A")
                        tooltipArray.push(`${localTime}<br/>`)
                        const text = thisPoint.text || ""
                        tooltipArray.push(text)
                        return tooltipArray
                    }

                    let tooltip = ""
                    this.points?.forEach((point) => {
                        const tz = getZoneInfoFromEnum(zoneInfo)
                        const start = formatTimestampWithTimezone(
                            point.point.x,
                            tz
                        )
                        const end = formatTimestampWithTimezone(
                            point.point.x2 || 0,
                            tz
                        )
                        const duration = formatTimestampsToDuration(
                            point.point.x,
                            point.point.x2
                        )
                        tooltip += `<b>${point.point.name || ""}</b><br/>
                            Start: ${start}<br/>
                            End: ${end}<br/>
                            Duration: ${duration}<br/>`
                    })

                    return tooltip
                },
            },
            series: motionSeries,
            exporting: {
                ...motionOptions.exporting,
                filename: wellName,
            },
        }

        setMotionOptions(updatedMotionOptions)
    }, [motionSeries, zoneInfo, wellIdentificationFragment])

    /** Handle rollup interval changes */
    useEffect(() => {
        const { selected, chartAggregationUnits } =
            getAggregationUnits(rollupInterval)

        const updateOptions: HighStock.Options = {
            ...runtimeOptions,
            xAxis: runtimeOptions.xAxis,
            time: {
                ...runtimeOptions.time,
                timezone: getZoneInfoFromEnum(zoneInfo),
            },
            plotOptions: {
                ...runtimeOptions.plotOptions,
                series: {
                    ...runtimeOptions.plotOptions?.series,
                    dataGrouping: {
                        enabled: true,
                        forced: true,
                        units: chartAggregationUnits,
                    },
                },
            },
        }

        setRuntimeOptions(updateOptions)
        setURLParams("dateInterval", selected)
        rollupIntervalRef.current = selected
    }, [rollupInterval])

    /** Refetch motion data when motionDate changes */
    useEffect(() => {
        if (!motionDate || !refetchMotionTimeline) return
        const fullDayInMS = 86400000
        const dayStart = motionDate.getTime()
        let dayEnd = dayStart + fullDayInMS

        // check if dayEnd is after current time
        if (dayEnd > new Date().getTime()) {
            dayEnd = new Date().getTime()
        }
        refetchMotionTimeline(dayStart, dayEnd)

        const updatedMotionOptions: HighStock.Options = {
            ...motionOptions,
            time: {
                timezone: getZoneInfoFromEnum(zoneInfo),
            },
            xAxis: {
                ...motionOptions.xAxis,
                min: dayStart,
                max: dayEnd,
                maxRange: fullDayInMS,
                events: {
                    afterSetExtremes: function (this, event) {
                        const currentRange = event.max - event.min
                        if (currentRange > fullDayInMS) {
                            this.setExtremes(dayStart, dayEnd, true)
                        }
                    },
                },
            },
        }

        setMotionOptions(updatedMotionOptions)
    }, [motionDate])

    /** This should get the start and end of the day set in motionDate */
    /** Also moves the motion chart to the selected date */
    useEffect(() => {
        if (!motionDate) return
        const fullDayInMS = 86400000
        const dayStart = motionDate.getTime()
        let dayEnd = dayStart + fullDayInMS
        // check if dayEnd is after current time
        if (dayEnd > new Date().getTime()) {
            dayEnd = new Date().getTime()
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const motionChart = (motionChartRef as any).current?.chart
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const runtimeChart = (runtimeChartRef as any).current?.chart
        if (motionChart && motionChart.xAxis[0]) {
            motionChart.xAxis[0].setExtremes(
                motionDate.getTime(),
                motionDate.getTime() + 86400000
            )

            if (runtimeChart && runtimeChart.series) {
                // First clear all existing selections
                runtimeChart.series.forEach((series: HighStock.Series) => {
                    if (!series.points) return
                    series.points.forEach((point) => {
                        point.select(false, false) // Deselect without triggering events
                    })
                })

                // Then select all points on the selected date
                runtimeChart.series.forEach((series: HighStock.Series) => {
                    if (
                        ["Run Time", "Idle Time", "Not Available"].includes(
                            series.name
                        )
                    ) {
                        if (!series.points) return
                        series.points.forEach((point) => {
                            // Check if point falls on the selected day
                            if (point.x >= dayStart && point.x < dayEnd) {
                                // For stacked columns, need to ensure point is valid
                                if (
                                    point &&
                                    typeof point.select === "function"
                                ) {
                                    point.select(true, true) // Select without triggering events
                                }
                            }
                        })
                    }
                })
            }
        }
    }, [motionDate, runtimeOptions])

    /** Setup Highcharts export functionality */
    useEffect(() => {
        if (!runTime?.highchartSeries) return
        ;(function (HighStock) {
            HighStock.Chart.prototype.getCSV = function () {
                if (this.series.length === 3) {
                    const idleInView = this.series[0].points
                        ? getHighChartsDataInView(this.series[0].points)
                        : []
                    const unknownInView = this.series[1].points
                        ? getHighChartsDataInView(this.series[1].points)
                        : []
                    const runtimeInView = this.series[2].points
                        ? getHighChartsDataInView(this.series[2].points)
                        : []

                    const allPointsInView: (number | null)[][][] = [
                        runtimeInView,
                        idleInView,
                        unknownInView,
                    ]
                    return convertNewRuntimeToCSV(allPointsInView, zoneInfo)
                }

                const observationInView = getXrangePointsInView(
                    this.series[1].points
                )
                return convertWellMotionToCSV(observationInView, zoneInfo)
            }

            HighStock.Chart.prototype.downloadXLS = function () {
                if (this.series.length === 3) {
                    const idleInView = this.series[0].points
                        ? getHighChartsDataInView(this.series[0].points)
                        : []
                    const unknownInView = this.series[1].points
                        ? getHighChartsDataInView(this.series[1].points)
                        : []
                    const runtimeInView = this.series[2].points
                        ? getHighChartsDataInView(this.series[2].points)
                        : []

                    const allPointsInView: (number | null)[][][] = [
                        runtimeInView,
                        idleInView,
                        unknownInView,
                    ]
                    return zipcelx({
                        filename:
                            wellIdentificationFragment?.name.replace(
                                /[^a-zA-Z0-9\-_]/g,
                                "_"
                            ) || "chart",
                        sheet: convertNewRuntimeToCelx(
                            allPointsInView,
                            zoneInfo
                        ),
                    })
                }

                const allPoints = this.series.map((series) =>
                    series.points.map((point) => point)
                )
                const dataInView = getHighChartsDataInView(allPoints.flat())
                return zipcelx({
                    filename:
                        wellIdentificationFragment?.name.replace(
                            /[^a-zA-Z0-9\-_]/g,
                            "_"
                        ) || "chart",
                    sheet: convertToZipcelxFormat(dataInView),
                })
            }
        })(HighStock)
    }, [runTime])

    let iwpBody: React.ReactNode

    if (iwpError || iwpMotionError) return <IwpWellDetailsChartError />

    const chartBody = iwpLoading ? (
        <IwpWellDetailsChartLoading />
    ) : (
        <>
            <div className={styles.container}>
                <HighchartsReact
                    highcharts={HighStock}
                    constructorType={"stockChart"}
                    options={runtimeOptions}
                    ref={runtimeChartRef}
                />
                <div className={styles.dateIntervalContainer}>
                    <Input
                        type="select"
                        onChange={(e) => handleRollupIntervalChange(e)}
                        orientation="horizontal"
                        disabled={false}
                        label="Rollup Interval"
                        options={[
                            { label: "Day", value: "day", disabled: false },
                            {
                                label: "Week",
                                value: "week",
                                disabled: false,
                            },
                            {
                                label: "Month",
                                value: "month",
                                disabled: false,
                            },
                        ]}
                        value={rollupInterval}
                        inputCustomClass="well-details-chart-date-interval-input"
                        customLabelClass="well-details-chart-date-interval-label"
                    />
                </div>
                {iwpMotionLoading ? (
                    <IwpWellDetailsChartLoading />
                ) : (
                    <>
                        <div className={styles.chartTitle}>
                            <div className={styles.motionChartDateLabel}>
                                {dayjs(motionDate)
                                    .tz(getZoneInfoFromEnum(zoneInfo))
                                    .format("MMMM D, YYYY")}
                            </div>
                            <ToolTip
                                tooltipContent={
                                    <div>
                                        Select a column from the above chart to
                                        view motion data for that day
                                    </div>
                                }
                                triggerChildren={
                                    <span className={styles.infoIcon}>
                                        <FontAwesomeIcon
                                            color="#0398FC"
                                            icon={faCircleInfo}
                                        />
                                    </span>
                                }
                            />
                        </div>
                        <HighchartsReact
                            highcharts={HighStock}
                            constructorType={"stockChart"}
                            options={motionOptions}
                            ref={motionChartRef}
                        />
                    </>
                )}
                <div className={styles.notAvailableLabel}>
                    <div className={styles.notAvailableTitle}>
                        Not Available:
                    </div>
                    data could be unavailable for one of these reasons: Loss of
                    power at the site OR Low battery power in a TREX sensor. A
                    lack of data will not prevent the well from operating.
                </div>
            </div>
        </>
    )

    if (runTime?.highchartSeries.length === 0) {
        iwpBody = <IwpWellDetailsEmptyDataSet />
    } else {
        iwpBody = chartBody
    }

    return (
        <>
            <Outlet />
            {dataInView ? (
                iwpBody
            ) : (
                <div className={styles.noDataInGraphViewWarning}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                    &nbsp; No data was found for these graph settings. Please
                    adjust settings and try again.
                </div>
            )}
        </>
    )
}

const IwpWellDetailsChartLoading = () => {
    return (
        <div className={classNames(styles.container, styles.messageContainer)}>
            <div className={styles.loadingText}> Loading </div>
            <SyncLoader color="#1e88e5" size={13} />
        </div>
    )
}

const IwpWellDetailsEmptyDataSet = () => {
    return (
        <div className={classNames(styles.container, styles.messageContainer)}>
            <Outlet />
            <div className={styles.emptyDataText}>
                There is currently no data found for this well. If data is
                expected, please reach out to{" "}
                <a href="mailto:support@petropower.com">
                    support@petropower.com
                </a>
            </div>
        </div>
    )
}

const IwpWellDetailsChartError = () => {
    return (
        <div className={classNames(styles.container, styles.messageContainer)}>
            <div className={styles.error}>Error</div>
        </div>
    )
}

export default IWPCharts
