import { graphql } from "../../generated"

export const WELL_NOTES_FOR_MODALS_FRAG = graphql(`
    fragment WellNotesForModalsFields on WellNote {
        id
        well {
            id
            name
        }
        note
        occurredAt {
            unixSeconds
        }
    }
`)

export const WELL_NOTES_FOR_IWP_FRAG = graphql(`
    fragment WellNotesForIwpFields on WellNote {
        id
        well {
            id
            name
        }
        note
        occurredAt {
            unixSeconds
        }
        createdBy {
            id
            identity {
                fullName
            }
        }
        updatedBy {
            id
            identity {
                fullName
            }
        }
    }
`)
