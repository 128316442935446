import {
    ControlSettingFieldsFragment,
    NotficationFragMyNotificationsFragment,
    ZoneInfo,
} from "../../../generated/graphql"
import { getZoneInfoFromEnum } from "../../../graphql/enum/zoneInfo"
import dayjs from "dayjs"
import HighStock from "highcharts/highstock"
import { getFragmentData } from "../../../generated"
import {
    CONTROL_SETTING_CONSTANT_IDLE_FIELDS_FRAGMENT,
    CONTROL_SETTING_OFF_FIELDS_FRAGMENT,
    CONTROL_SETTING_ON_FIELDS_FRAGMENT,
    CONTROL_SETTING_TIMER_FIELDS_FRAGMENT,
} from "../../../graphql/fragments/wellControlSetting"
import { IDENTITY_FIELDS_FRAGMENT } from "../../../graphql/fragments/Identity"
import { formatAlertMetricValueWithUnit } from "../../notifications/rules/manageAlertRule/alerts_GraphQL/alertRuleMetricFragment"
import { MY_NOTIFICATIONS_ABS_ROUTE } from "../../.."

export interface IWPAnnotations {
    runtimeAnnotations: HighStock.SeriesOptionsType
    motionAnnotations: HighStock.SeriesOptionsType
}

export const FlagsFromControlSettings = (
    controlSettings: ControlSettingFieldsFragment[],
    zoneInfo: ZoneInfo,
    // earliest timestamp available used for filtering
    // this is a temp fix as this logic will be moved effectively to ngapi
    earliestTimestamp: number | null
): IWPAnnotations => {
    // map of date to array of counts
    const runtimeByDayFlags: Map<number, HighStock.PointOptionsObject[]> =
        new Map()
    const motionFlags: HighStock.PointOptionsObject[] = []
    const runtimeFlagsData: HighStock.PointOptionsObject[] = []

    // loop through control settings,
    // if more than one setting on a given day,
    // flag title will be the count of settings
    // if only one setting on a given day,
    // flag title will be the setting name
    controlSettings.forEach((controlSetting) => {
        let createdBy: string | undefined
        let xTimestamp = 0
        let title = ""
        let text = ""
        switch (controlSetting.__typename) {
            case "WellControlSettingOn": {
                const onSetting = getFragmentData(
                    CONTROL_SETTING_ON_FIELDS_FRAGMENT,
                    controlSetting
                )
                createdBy = getFragmentData(
                    IDENTITY_FIELDS_FRAGMENT,
                    onSetting.createdBy.identity
                )?.fullName
                xTimestamp = onSetting.createdAt.unixMilliseconds
                title = "ON"
                break
            }
            case "WellControlSettingOff": {
                const offSetting = getFragmentData(
                    CONTROL_SETTING_OFF_FIELDS_FRAGMENT,
                    controlSetting
                )
                createdBy = getFragmentData(
                    IDENTITY_FIELDS_FRAGMENT,
                    offSetting.createdBy.identity
                )?.fullName
                xTimestamp = offSetting.createdAt.unixMilliseconds
                title = "OFF"
                break
            }
            case "WellControlSettingTimer": {
                const timerSetting = getFragmentData(
                    CONTROL_SETTING_TIMER_FIELDS_FRAGMENT,
                    controlSetting
                )
                createdBy = getFragmentData(
                    IDENTITY_FIELDS_FRAGMENT,
                    timerSetting.createdBy.identity
                )?.fullName
                xTimestamp = timerSetting.createdAt.unixMilliseconds
                title = "TIMER"
                text = `Run Duration: ${timerSetting.runDuration.hours}h ${timerSetting.runDuration.minutes}m<br/>Idle Duration: ${timerSetting.idleDuration.hours}h ${timerSetting.runDuration.minutes}m<br/>Period: ${timerSetting.period.hours}h ${timerSetting.period.minutes}m<br/>`
                break
            }
            case "WellControlSettingConstantIdle": {
                const idleSetting = getFragmentData(
                    CONTROL_SETTING_CONSTANT_IDLE_FIELDS_FRAGMENT,
                    controlSetting
                )
                createdBy = getFragmentData(
                    IDENTITY_FIELDS_FRAGMENT,
                    idleSetting.createdBy.identity
                )?.fullName
                xTimestamp = idleSetting.createdAt.unixMilliseconds
                title = "SMART"
                text = `Constant Idle: ${idleSetting.constantIdle.hours}h ${idleSetting.constantIdle.minutes}m<br/>Initial Run: ${idleSetting.initialRun.hours}h ${idleSetting.initialRun.minutes}m<br/>Target Empty Ratio: ${idleSetting.targetEmptyRatio}<br/>`
                break
            }
            case "WellControlSettingUnspecified":
            default:
                break
        }

        if (earliestTimestamp && xTimestamp < earliestTimestamp) {
            return
        }

        const midnightTimestamp = dayjs(xTimestamp)
            .tz(getZoneInfoFromEnum(zoneInfo))
            .startOf("day")
            .valueOf()

        const timeOfDay = dayjs(xTimestamp)
            .tz(getZoneInfoFromEnum(zoneInfo))
            .format("HH:mm A")

        const actualFlag: HighStock.PointOptionsObject = {
            x: xTimestamp,
            title,
            text,
            custom: {
                timeOfDay,
            },
        }

        const existingFlag = runtimeByDayFlags.get(midnightTimestamp)

        runtimeByDayFlags.set(midnightTimestamp, [
            ...(existingFlag || []),
            actualFlag,
        ])

        motionFlags.push({
            x: xTimestamp,
            title,
            text: text + `Created by: ${createdBy}`,
            custom: {
                timeOfDay,
            },
        })
    })

    runtimeByDayFlags.forEach((flags, timestamp) => {
        let title = flags[0].title
        let text = flags[0].text
        if (flags.length > 1) {
            title = flags.length.toString()
            text = flags
                .map((flag) => {
                    return `${flag.title}: ${flag.custom?.timeOfDay}`
                })
                .join(",<br/>")
        }

        runtimeFlagsData.push({
            x: timestamp,
            title,
            text,
        })
    })

    // sort flags
    runtimeFlagsData.sort((a, b) => {
        if (a.x && b.x) {
            return a.x - b.x
        }
        return 0
    })
    motionFlags.sort((a, b) => {
        if (a.x && b.x) {
            return a.x - b.x
        }
        return 0
    })

    const flags: HighStock.SeriesOptionsType = {
        type: "flags",
        shape: "squarepin",
        name: "Control Settings",
        zIndex: 1,
        color: "orange",
        lineColor: "orange",
        dashStyle: "ShortDash",
        style: {
            color: "orange",
        },
        data: motionFlags,
        allowPointSelect: false,
    }

    return {
        runtimeAnnotations: {
            type: "flags",
            shape: "squarepin",
            name: "Control Settings",
            zIndex: 1,
            color: "orange",
            lineColor: "orange",
            dashStyle: "ShortDash",
            legendIndex: 97,
            style: {
                color: "orange",
            },
            onSeries: "Idle Time",
            data: runtimeFlagsData,
            allowPointSelect: false,
        },
        motionAnnotations: flags,
    }
}

export const FlagsFromNotifications = (
    notifications: NotficationFragMyNotificationsFragment[],
    zoneInfo: ZoneInfo
): IWPAnnotations => {
    // map of date to array of counts
    const runtimeByDayFlags: Map<number, HighStock.PointOptionsObject[]> =
        new Map()
    const motionFlags: HighStock.PointOptionsObject[] = []
    const runtimeFlagsData: HighStock.PointOptionsObject[] = []

    // loop through control settings,
    // if more than one setting on a given day,
    // flag title will be the count of settings
    // if only one setting on a given day,
    // flag title will be the setting name
    notifications.forEach((notification) => {
        const xTimestamp = notification.event.triggeredTime.unixMilliseconds
        const midnightTimestamp = dayjs(xTimestamp)
            .tz(getZoneInfoFromEnum(zoneInfo))
            .startOf("day")
            .valueOf()
        const timeOfDay = dayjs(xTimestamp)
            .tz(getZoneInfoFromEnum(zoneInfo))
            .format("HH:mm A")

        const title = `🔔`
        const custom = {
            ruleName: notification.event.alertRule.name,
            resolvedAt: notification.event.resolvedTime?.unixMilliseconds,
            timeOfDay,
        }
        const alertTriggerValue = notification.event.triggeredValue
        const alertRuleConditionUnit =
            notification.event.alertRule.condition.unit
        const triggerValueWithUnit = formatAlertMetricValueWithUnit(
            alertTriggerValue,
            alertRuleConditionUnit,
            "LargestSingleUnit"
        )

        const text = `${notification.event.alertRule.name}<br/> Trigger value: ${triggerValueWithUnit}`

        const actualFlag: HighStock.PointOptionsObject = {
            x: xTimestamp,
            title,
            text,
            custom,
            // shape
        }

        // make well name url safe
        const wellName = encodeURIComponent(notification.event.well.name)
        const metricName = encodeURIComponent(
            notification.event.alertRule.metric.displayName
        )
        const link = `${MY_NOTIFICATIONS_ABS_ROUTE}?well=${wellName}&metric=${metricName}`
        const motionText = `${text}<br/><a style="color: #0398FC" href=${link} target="_blank">View Notification</a>`

        const existingFlag = runtimeByDayFlags.get(midnightTimestamp)

        runtimeByDayFlags.set(midnightTimestamp, [
            ...(existingFlag || []),
            actualFlag,
        ])

        // check if notification resolved at exists and is over a month ago
        const isResolvedAndOld =
            notification.event.resolvedTime &&
            dayjs(notification.event.resolvedTime.unixMilliseconds).isBefore(
                dayjs().subtract(30, "day")
            )

        motionFlags.push({
            x: xTimestamp,
            title,
            text: isResolvedAndOld ? text : motionText,
            custom,
        })
    })

    runtimeByDayFlags.forEach((flags, timestamp) => {
        let title = flags[0].title
        let text = flags[0].text
        if (flags.length > 1) {
            title = flags.length.toString()
            text = flags
                .map((flag) => {
                    return `${flag.custom?.ruleName}: ${flag.custom?.timeOfDay}`
                })
                .join(",<br/>")
        }

        runtimeFlagsData.push({
            x: timestamp,
            title,
            text,
        })
    })

    // sort flags
    runtimeFlagsData.sort((a, b) => {
        if (a.x && b.x) {
            return a.x - b.x
        }
        return 0
    })
    motionFlags.sort((a, b) => {
        if (a.x && b.x) {
            return a.x - b.x
        }
        return 0
    })

    const flags: HighStock.SeriesOptionsType = {
        type: "flags",
        shape: "squarepin",
        name: "Notifications",
        zIndex: 1,
        color: "red",
        lineColor: "red",
        dashStyle: "ShortDash",
        style: {
            color: "red",
        },
        data: motionFlags,
        allowPointSelect: false,
    }

    return {
        runtimeAnnotations: {
            type: "flags",
            shape: "squarepin",
            name: "Notifications",
            zIndex: 1,
            color: "red",
            lineColor: "red",
            dashStyle: "ShortDash",
            legendIndex: 97,
            style: {
                color: "red",
            },
            onSeries: "Idle Time",
            data: runtimeFlagsData,
            allowPointSelect: false,
        },
        motionAnnotations: flags,
    }
}
