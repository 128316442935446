import { graphql } from "../../generated"

/**
 * Well notes mutations should return the fields for IWP so that the cache is properly updated after the mutation
 */

export const WELL_NOTES_ADD = graphql(`
    mutation AddWellNote($WellNotesCreateInput: WellNotesCreateInput!) {
        wellNotesCreate(input: $WellNotesCreateInput) {
            wellNotes {
                ...WellNotesForIwpFields
            }
        }
    }
`)

export const WELL_NOTES_UPDATE = graphql(`
    mutation UpdateWellNote($WellNotesUpdateInput: WellNotesUpdateInput!) {
        wellNotesUpdate(input: $WellNotesUpdateInput) {
            wellNote {
                ...WellNotesForIwpFields
            }
        }
    }
`)

export const WELL_NOTES_DELETE = graphql(`
    mutation DeleteWellNote($WellNotesDeleteInput: WellNotesDeleteInput!) {
        wellNotesDelete(input: $WellNotesDeleteInput) {
            ids
        }
    }
`)
