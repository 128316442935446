import HighStock from "highcharts/highstock"
import { convertSecondsToHoursAndMinutes } from "../../../util/datesAndTimes/datesAndTimes"

const baseChartOptions: HighStock.Options = {
    credits: {
        enabled: false,
    },
    accessibility: {
        enabled: false,
    },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
}

const oldWellDetailsChartOptions: HighStock.Options = {
    ...baseChartOptions,
    boost: {
        enabled: true,
    },
    rangeSelector: {
        enabled: true,
        buttons: [
            {
                type: "week",
                count: 1,
                text: "1w",
            },
            {
                type: "month",
                count: 1,
                text: "1m",
            },
            {
                type: "month",
                count: 3,
                text: "3m",
            },
            {
                type: "year",
                count: 1,
                text: "1y",
            },
        ],
    },
    chart: {
        zooming: {
            mouseWheel: {
                enabled: true,
            },
        },
    },
    title: {
        text: "",
    },
    legend: {
        enabled: true,
        symbolPadding: 0,
        symbolWidth: 0,
        symbolHeight: 0,
        labelFormatter: function () {
            if (this.name === "Spm") return ""
            return (
                '<span style="color: ' +
                this.color +
                '">' +
                this.name +
                "</span>"
            )
        },
    },
    yAxis: [
        {
            title: {
                text: "Hours",
                style: {
                    color: "#FF9800",
                    fontWeight: "400",
                },
            },
            opposite: false,
            labels: {
                enabled: true,
                style: {
                    color: "#FF9800",
                },
                align: "right",
                formatter: function () {
                    if (typeof this.value === "number") {
                        const { hours } = convertSecondsToHoursAndMinutes(
                            this.value
                        )
                        return `${hours}`
                    } else {
                        return this.value
                    }
                },
            },
            offset: 10,
            gridLineColor: "#CFD8DC",
            tickPositions: [
                3600 * 0,
                3600 * 4,
                3600 * 8,
                3600 * 12,
                3600 * 16,
                3600 * 20,
                3600 * 24,
            ],
            resize: {
                enabled: false,
            },
            showLastLabel: true,
        },
    ],
    tooltip: {
        backgroundColor: "#37474f",
        style: {
            color: "#fff",
        },
        formatter: function () {
            const toolTipArray = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { currentDataGrouping } = this.series as any
            const unitName = currentDataGrouping?.unitName

            let formatVariables: string
            switch (unitName) {
                case "day":
                    formatVariables = "%e %b %Y"
                    break
                case "week":
                    formatVariables = "%A, %e %b %Y"
                    break
                case "month":
                    formatVariables = "%B %Y"
                    break
                default:
                    formatVariables = "%e %b %Y"
                    break
            }

            const formattedDate = HighStock.dateFormat(
                formatVariables,
                this.x as number
            )
            toolTipArray.push(formattedDate)
            const points = this.points

            if (points) {
                points.forEach((point) => {
                    if (point.series.name === "Run Time") {
                        if (point.y || point.y === 0) {
                            const { hours, minutes } =
                                convertSecondsToHoursAndMinutes(point.y)

                            toolTipArray.push(
                                `Running Avg: ${hours}h ${minutes}m`
                            )
                        }
                    } else {
                        toolTipArray.push(`SPM: ${this.y}`)
                    }
                })
            }

            return toolTipArray
        },
    },
    xAxis: {
        type: "datetime",
        labels: {
            style: {
                color: "#37474f",
            },
        },
        lineColor: "#CFD8DC",
        tickLength: 0,
        minRange: 3 * 24 * 60 * 60 * 1000,
    },
    plotOptions: {
        series: {
            boostThreshold: 100,
            marker: {
                enabled: true,
            },
        },
    },
    series: [
        {
            type: "line",
            data: [],
            name: "Run Time",
            yAxis: 0,
            color: "#FF9800",
            boostThreshold: 100,
            marker: {
                symbol: "circle",
                radius: 2,
            },
        },
    ],
    exporting: {
        csv: {
            dateFormat: "%m-%d-%Y",
        },
        enabled: true,
        buttons: {
            contextButton: {
                align: "right",
                verticalAlign: "top",
                y: -4,
                symbolStroke: "#1e88e5",
                menuItems: [
                    "downloadPNG",
                    "downloadJPEG",
                    "downloadPDF",
                    "downloadSVG",
                    "separator",
                    "downloadCSV",
                    "downloadXLS",
                    "separator",
                    "printChart",
                    "viewFullscreen",
                ],
            },
        },
    },
}

const wellRunTimeChartOptions: HighStock.Options = {
    ...baseChartOptions,
    boost: {
        enabled: true,
    },
    rangeSelector: {
        enabled: true,
        buttons: [
            {
                type: "week",
                count: 1,
                text: "1w",
            },
            {
                type: "month",
                count: 1,
                text: "1m",
            },
            {
                type: "month",
                count: 3,
                text: "3m",
            },
            {
                type: "year",
                count: 1,
                text: "1y",
            },
        ],
    },
    chart: {
        type: "column",
        zooming: {
            mouseWheel: {
                enabled: true,
            },
        },
        height: 450,
    },
    title: {
        text: "",
    },
    legend: {
        // allows for the legend to be displayed on two rows
        width: 400,
        itemMarginBottom: 20,
        // reversed: true,
        enabled: true,
        symbolPadding: 5,
        symbolRadius: 0,
        squareSymbol: true,
        labelFormatter: function () {
            return "<span>" + this.name + "</span>"
        },
    },
    yAxis: [
        {
            type: "datetime",
            title: {
                text: "",
                style: {
                    color: "#FF9800",
                    fontWeight: "400",
                },
                offset: 10,
            },
            opposite: false,
            labels: {
                enabled: true,
                align: "right",
                formatter: function () {
                    if (typeof this.value === "number") {
                        const hours = Math.floor(this.value / 3600)
                        const minutes = Math.floor((this.value % 3600) / 60)
                        if (hours > 0) {
                            return `${hours}h ${minutes}m`
                        }
                        return `${minutes}m`
                    } else {
                        return this.value
                    }
                },
            },
            offset: 10,
            gridLineColor: "#CFD8DC",
            // 30 minutes in seconds
            minTickInterval: 30 * 60,
            startOnTick: true,
            resize: {
                enabled: false,
            },
            showLastLabel: true,
        },
    ],
    tooltip: {
        backgroundColor: "#37474f",
        style: {
            color: "#fff",
        },
    },
    xAxis: {
        type: "datetime",
        labels: {
            style: {
                color: "#37474f",
            },
        },
        lineColor: "#CFD8DC",
        tickLength: 0,
        minRange: 3 * 24 * 60 * 60 * 1000,
        ordinal: false,
    },
    plotOptions: {
        column: {
            stacking: "normal",
        },
        xrange: {
            borderRadius: 0,
        },
        series: {
            allowPointSelect: true,
            states: {
                select: {
                    color: undefined,
                    borderColor: "#37474f",
                    borderWidth: 2,
                },
            },
            marker: {
                enabled: true,
            },
        },
    },
    series: [
        {
            type: "line",
            data: [],
            name: "Run Time",
            yAxis: 0,
            color: "#FF9800",
            boostThreshold: 100,
            marker: {
                symbol: "circle",
                radius: 2,
            },
        },
    ],
    exporting: {
        csv: {
            dateFormat: "%m-%d-%Y",
        },
        enabled: true,
        buttons: {
            contextButton: {
                align: "right",
                verticalAlign: "top",
                y: -4,
                symbolStroke: "#1e88e5",
                menuItems: [
                    "downloadPNG",
                    "downloadJPEG",
                    "downloadPDF",
                    "downloadSVG",
                    "separator",
                    "downloadCSV",
                    "downloadXLS",
                    "separator",
                    "printChart",
                    "viewFullscreen",
                ],
            },
        },
    },
}

const wellMotionChartOptions: HighStock.Options = {
    ...baseChartOptions,
    exporting: {
        enabled: true,
        buttons: {
            contextButton: {
                align: "right",
                verticalAlign: "top",
                y: -4,
                symbolStroke: "#1e88e5",
                menuItems: [
                    "downloadPNG",
                    "downloadJPEG",
                    "downloadPDF",
                    "downloadSVG",
                    "separator",
                    "downloadCSV",
                    "separator",
                    "printChart",
                    "viewFullscreen",
                ],
            },
        },
    },

    chart: {
        type: "xrange",
        height: 200,
        marginTop: 40, // Add margin at the top
        panning: {
            enabled: false,
        },
    },
    rangeSelector: {
        enabled: false,
        inputEnabled: false,
    },
    yAxis: [
        {
            opposite: false,
            categories: ["Intent", "Motion"],
            // reversed: true,
            offset: 0,
            labels: {
                align: "center",
                style: {
                    textAlign: "center",
                },
                formatter: function () {
                    if (typeof this.value === "string") {
                        return this.value
                    }

                    return ""
                },
            },
        },
    ],
    xAxis: {
        type: "datetime",
        ordinal: false,
        min: new Date().getTime() - 30 * 24 * 60 * 60 * 1000, // 30 days ago
    },
    series: [
        {
            type: "xrange",
            data: [],
            name: "Motion",
            yAxis: 0,
            color: "#1e88e5",
            turboThreshold: 0,
        },
        {
            type: "xrange",
            data: [],
            name: "Intent",
            yAxis: 0,
            color: "#FF0000",
            turboThreshold: 0,
        },
    ],
    plotOptions: {
        series: {
            turboThreshold: 0,
        },
        xrange: {
            grouping: false,
            pointWidth: 20,
        },
    },
    tooltip: {
        backgroundColor: "#37474f",
        style: {
            color: "#fff",
        },
        shared: true,
        outside: true,
        snap: 0,
    },
}

export {
    oldWellDetailsChartOptions,
    wellRunTimeChartOptions,
    wellMotionChartOptions,
}
